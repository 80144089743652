import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import store from '@/store';
import { Actions, Mutations } from '@/store/enums/StoreEnums';
import JwtService, { USER_TYPE } from '@/core/services/JwtService';
import { nextTick } from 'vue';
import AuthService from '@/core/services/AuthService';
import warehouseRouters from '@/views/modules/warehouse/router';
import accountingRouters from '@/views/modules/accounting/router';
import cargoRouters from '@/views/modules/cargo/router';
import hrRouters from '@/views/modules/hr/router';
import AttendanceRouters from '@/views/modules/attendance/router';
import CustomsRouters from '@/views/modules/customs/router';
import SalariesRouters from '@/views/modules/salaries/router';
import { CookieService } from '@/core/services/CookieService';
import i18n from '@/core/plugins/i18n';
import restaurantsRouters from '@/views/modules/restaurants/router';
import posRouters from '@/views/modules/pos/router';
import electronicApprovalsRoutes from '@/views/modules/electronic-approvals/router';
import electronicApprovalsGeneralRouter from '@/views/modules/electronic-approvals/router/generalSettingRouter';
import assetsRoutes from '@/views/modules/assets/router';
import assetsGeneralRouter from '@/views/modules/assets/router/generalSettingRouter';
import posGeneralRouter from '@/views/modules/pos/router/generalSettingRouter';
import UrlService from '@/core/services/UrlService';
import adminRouters from '@/views/admin-panel/router';
import { AdminRoles } from '@/views/admin-panel/views/shared/constants/AdminRoles';
import casherRouters from '@/views/modules/casher/router';
import { PosActions } from '@/views/modules/casher/store/modules/POS';
import { MODULES_IDS } from '@/views/modules/shared/constants/ModulesNames';

const routes: Array<RouteRecordRaw> = [
   {
      path: '/external-customs-broker-deals',
      name: 'external-customs-broker-deals',
      component: () => import('@/components/page-layouts/PublicClient.vue'),
      children: [
         {
            path: '',
            name: 'external-customs-broker-deals-list',
            component: () =>
               import(
                  '@/views/modules/customs/views/daily-movements/deals/ListExternalCustomsBroker.vue'
               ),
         },
         {
            path: '/external-customs-broker-deals/:id/view',
            name: 'external-customs-broker-deals-view',
            component: () =>
               import(
                  '@/views/modules/customs/views/daily-movements/deals/external-customs-broker/View.vue'
               ),
         },
      ],
   },
   {
      path: '/external-client',
      component: () => import('@/components/page-layouts/PublicClient.vue'),
      children: [
         {
            path: 'transport-management',
            component: () =>
               import('@/views/modules/cargo/views/daily-movements/external-client/base.vue'),
            children: [
               {
                  path: '',
                  name: 'external-client-transport-transactions-list',
                  component: () =>
                     import(
                        '@/views/modules/cargo/views/daily-movements/external-client/transport-movements/List.vue'
                     ),
               },
               {
                  path: ':id/view/:type',
                  name: 'external-client-transport-transactions-view',
                  component: () =>
                     import(
                        '@/views/modules/cargo/views/daily-movements/external-client/transport-movements/View.vue'
                     ),
               },
               {
                  path: 'downloads',
                  name: 'external-client-download-transactions-list',
                  component: () =>
                     import(
                        '@/views/modules/cargo/views/daily-movements/external-client/download-movements/List.vue'
                     ),
               },
               {
                  path: 'downloads/:id/view',
                  name: 'external-client-download-transactions-view',
                  component: () =>
                     import(
                        '@/views/modules/cargo/views/daily-movements/external-client/download-movements/View.vue'
                     ),
               },
               {
                  path: 'sales',
                  name: 'external-client-sales-list',
                  component: () =>
                     import(
                        '@/views/modules/cargo/views/settings/invoices/external-client/sales/List.vue'
                     ),
               },
            ],
         },
         //Customs Route
         {
            path: 'customs',
            component: () =>
               import('@/views/modules/customs/views/daily-movements/external-client/base.vue'),
            children: [
               {
                  path: 'external-client-deals',
                  name: 'external-client-deals',
                  children: [
                     {
                        path: '',
                        name: 'external-client-deals-list',
                        component: () =>
                           import(
                              '@/views/modules/customs/views/daily-movements/deals/ListExternalClient.vue'
                           ),
                     },
                     {
                        path: 'add',
                        name: 'external-client-deals-add',
                        component: () =>
                           import(
                              '@/views/modules/customs/views/daily-movements/deals/external-client/Add.vue'
                           ),
                     },
                     {
                        path: 'edit/:id',
                        name: 'external-client-deals-edit',
                        component: () =>
                           import(
                              '@/views/modules/customs/views/daily-movements/deals/external-client/Add.vue'
                           ),
                     },
                     {
                        path: ':id/:view',
                        name: 'external-client-deals-view',
                        component: () =>
                           import(
                              '@/views/modules/customs/views/daily-movements/deals/external-client/View.vue'
                           ),
                     },
                  ],
               },
               {
                  path: 'invoices',
                  name: 'external-customs-invoices',
                  children: [
                     {
                        path: '',
                        name: 'external-customs-invoices-list',
                        component: () =>
                           import(
                              '@/views/modules/customs/views/daily-movements/external-client/invoices/List.vue'
                           ),
                     },
                     {
                        path: 'add',
                        name: 'external-customs-invoices-add',
                        component: () =>
                           import(
                              '@/views/modules/customs/views/daily-movements/deals/external-client/Add.vue'
                           ),
                     },
                     {
                        path: 'edit/:id',
                        name: 'external-customs-invoices-edit',
                        component: () =>
                           import(
                              '@/views/modules/customs/views/daily-movements/deals/external-client/Add.vue'
                           ),
                     },
                     {
                        path: ':id/:view',
                        name: 'external-customs-invoices-view',
                        component: () =>
                           import(
                              '@/views/modules/customs/views/daily-movements/deals/external-client/View.vue'
                           ),
                     },
                  ],
               },
            ],
         },
      ],
   },
   {
      path: '/external-vendor',
      component: () => import('@/components/page-layouts/PublicClient.vue'),
      children: [
         {
            path: 'transport-management',
            component: () =>
               import('@/views/modules/cargo/views/daily-movements/external-vendor/base.vue'),
            children: [
               {
                  path: '',
                  name: 'external-vendor-transport-transactions-list',
                  component: () =>
                     import(
                        '@/views/modules/cargo/views/daily-movements/external-vendor/transport-movements/List.vue'
                     ),
               },
               {
                  path: ':id/view/:type',
                  name: 'external-vendor-transport-transactions-view',
                  component: () =>
                     import(
                        '@/views/modules/cargo/views/daily-movements/external-vendor/transport-movements/View.vue'
                     ),
               },
               {
                  path: 'downloads',
                  name: 'external-vendor-download-transactions-list',
                  component: () =>
                     import(
                        '@/views/modules/cargo/views/daily-movements/external-vendor/download-movements/List.vue'
                     ),
               },
               {
                  path: 'downloads/:id/view',
                  name: 'external-vendor-download-transactions-view',
                  component: () =>
                     import(
                        '@/views/modules/cargo/views/daily-movements/external-vendor/download-movements/View.vue'
                     ),
               },
               {
                  path: 'purchase',
                  name: 'external-vendor-purchase-list',
                  component: () =>
                     import(
                        '@/views/modules/cargo/views/settings/invoices/external-vendor/purchase/List.vue'
                     ),
               },
            ],
         },
      ],
   },
   {
      path: '/',
      name: 'LandingPage',
      meta: {
         title: 'landing page main page desc 3',
      },
      component: () => import('@/views/LandingPage.vue'),
      beforeEnter: (to, from, next) => {
         if (store.getters.isUserAuthenticated) {
            if (CookieService.getCookie('userType') == USER_TYPE.ADMIN) next();
            return next('/dashboard');
         } else {
            next();
         }
      },
   },
   {
      path: '/',
      name: 'casher',
      component: () => import('@/components/page-layouts/CasherLayout.vue'),
      children: [
         {
            path: '/casher',
            name: 'casher-home',
            redirect: '/casher/new-invoice',
            meta: {
               title: 'Casher',
               // permission: 'cashers'
            },
            component: () => import('@/views/modules/casher/base.vue'),
            children: [...casherRouters],
         },
      ],
   },
   {
      path: '/',
      component: () => import('@/components/page-layouts/Subscription.vue'),
      children: [
         {
            path: '/select-modules/:type?',
            name: 'select-modules',
            component: () => import('@/views/SelectModules.vue'),
         },
      ],
   },
   {
      path: '/',
      name: 'Dashboard',
      component: () => import('@/layout/Layout.vue'),
      children: [
         {
            path: '/dashboard',
            name: 'dashboard',
            meta: {
               title: 'Welcome to DOSO System...',
            },
            component: () => import('@/views/Dashboard.vue'),
            beforeEnter: (to, from, next) => {
               if (store.getters.isUserAuthenticated && CookieService.getCookie('X-Tenant-Id')) {
                  if (!(redirectToCashier() && !JwtService.isAdminDomain())) next();
               } else if (store.getters.isUserAuthenticated && JwtService.isAdminDomain()) {
                  return next('/admin-panel');
               } else {
                  next('/sign-in');
               }
            },
         },
         {
            path: '/select-modules/:type?',
            name: 'select-modules',
            component: () => import('@/views/SelectModules.vue'),
         },

         {
            path: '/subscription-plan',
            name: 'subscription-plan',
            component: () => import('@/views/subscription/subscriptionPlan/subscriptionPlan.vue'),
         },
         {
            path: '/subscription-plan/:renew',
            name: 'subscription-plan-renew',
            component: () => import('@/views/subscription/subscriptionPlan/subscriptionPlan.vue'),
         },
         {
            path: '/update-subscription-plan',
            name: 'update-subscription-plan',
            component: () =>
               import('@/views/subscription/subscriptionPlan/updateSubscriptionPlan.vue'),
         },
         {
            path: '/welcome',
            name: 'welcome',
            component: () => import('@/views/pages/welcome.vue'),
         },
         {
            path: '/waiting-admin-approval',
            name: 'waiting-admin-approval',
            component: () => import('@/views/subscription/WaitingAdminApproval.vue'),
         },
         {
            path: '/transaction-rejected',
            name: 'transaction-rejected',
            component: () => import('@/views/subscription/TransactionRejected.vue'),
         },
      ],
   },

   {
      path: '/',
      component: () => import('@/components/page-layouts/Modules.vue'),
      children: [
         {
            path: '/settings',
            name: 'settings',
            redirect: '/settings/general-settings',
            component: () => import('@/views/modules/settings/base.vue'),

            children: [
               {
                  path: '/user-profile',
                  name: 'user-profile',
                  component: () => import('@/views/pages/user-profile/edit.vue'),
                  meta: {
                     title: 'My Profile',
                  },
               },
               {
                  path: 'other-settings',
                  name: 'other-settings',

                  component: () =>
                     import('@/views/modules/settings/views/settings/other/other.vue'),
                  children: [],
               },
               {
                  path: 'common',
                  name: 'common-settings',
                  component: () =>
                     import(
                        '@/views/modules/settings/views/settings/common-settings/invoice-settings.vue'
                     ),
                  children: [
                     {
                        path: 'activity-log',
                        name: 'activity-log',
                        meta: {
                           // permission: "client_groups",
                           title: 'Activity Log',
                        },
                        component: () =>
                           import('@/views/modules/invoicing/views/settings/activity-log.vue'),
                     },
                     {
                        path: 'warehouse-setting',
                        name: 'common-settings-warehouse',
                        meta: {
                           permission: 'warehouse_setting',
                           title: 'Warehouse settings',
                           type: 'read',
                        },
                        component: () =>
                           import('@/views/modules/warehouse/views/settings/Setting.vue'),
                     },
                     {
                        path: 'commission-policy',
                        name: 'common-settings-commission-policy',
                        meta: {
                           permission: 'commission_policies',
                           title: 'Commission Policy',
                        },
                        component: () =>
                           import(
                              '@/views/modules/invoicing/views/settings/commission-policy/Settings.vue'
                           ),
                     },
                     {
                        path: 'model-settings',
                        name: 'settings-model-settings',
                        meta: {
                           permission: 'invoice_models',
                           title: 'Workflow Forms',
                           type: 'read',
                        },
                        component: () =>
                           import(
                              '@/views/modules/invoicing/views/settings/invoice-model/List.vue'
                           ),
                     },
                     {
                        path: 'add-model-settings',
                        name: 'settings-add-model-settings',
                        meta: {
                           permission: 'invoice_models',
                           title: 'Workflow Forms',
                           type: 'write',
                        },
                        component: () =>
                           import('@/views/modules/invoicing/views/settings/invoice-model/Add.vue'),
                     },
                     {
                        path: 'add-model-settings/duplicate/:duplicationId',
                        name: 'settings-add-duplicate-model-settings',
                        meta: {
                           permission: 'invoice_models',
                           title: 'Workflow Forms',
                           type: 'write',
                        },
                        component: () =>
                           import('@/views/modules/invoicing/views/settings/invoice-model/Add.vue'),
                     },
                     {
                        path: 'update-model-settings/:id',
                        name: 'settings-update-model-settings',
                        props: true,
                        meta: {
                           permission: 'invoice_models',
                           title: 'Workflow Forms',
                           type: 'write',
                        },
                        component: () =>
                           import('@/views/modules/invoicing/views/settings/invoice-model/Add.vue'),
                     },
                     {
                        path: 'view-model-settings/:id',
                        name: 'settings-view-model-settings',
                        props: true,
                        meta: {
                           permission: '',
                           title: 'Workflow Forms',
                        },
                        component: () =>
                           import('@/views/modules/invoicing/views/settings/invoice-model/Add.vue'),
                     },

                     {
                        path: 'product-categories',
                        name: 'product-categories',
                        meta: {
                           permission: 'product_categories',
                           title: 'Product Categories',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/common-settings/product-categories/List.vue'
                           ),
                     },
                     {
                        path: 'products',
                        name: 'settings-products',
                        meta: {
                           permission: 'products',
                           title: 'products',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/common-settings/products/main.vue'
                           ),
                        children: [
                           {
                              path: 'add-product',
                              name: 'settings-add-product',
                              meta: {
                                 permission: 'products',
                                 title: 'products',
                                 type: 'write',
                              },
                              component: () =>
                                 import(
                                    '@/views/modules/settings/views/settings/common-settings/products/Add.vue'
                                 ),
                           },
                           {
                              path: 'update-product/:id',
                              name: 'settings-update-product',
                              meta: {
                                 permission: 'products',
                                 title: 'products',
                                 type: 'write',
                              },
                              component: () =>
                                 import(
                                    '@/views/modules/settings/views/settings/common-settings/products/Add.vue'
                                 ),
                           },
                           {
                              path: 'view-product/:id',
                              name: 'settings-view-product',
                              meta: {
                                 permission: 'products',
                                 title: 'products',
                                 type: 'write',
                              },
                              component: () =>
                                 import(
                                    '@/views/modules/settings/views/settings/common-settings/products/Add.vue'
                                 ),
                           },
                        ],
                     },
                     {
                        path: 'import-result',
                        name: 'settings-import-result',
                        meta: {
                           permission: 'log_exports',
                           title: 'Import Result',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/common-settings/import-log/Result.vue'
                           ),
                     },
                     {
                        path: 'import-log',
                        name: 'settings-import-log',
                        meta: {
                           permission: 'log_exports',
                           title: 'Import Log',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/common-settings/import-log/Log.vue'
                           ),
                     },
                     {
                        path: 'warehouse',
                        name: 'warehouse',
                        meta: {
                           permission: 'warehouses',
                           title: 'Warehouses',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/common-settings/warehouse/List.vue'
                           ),
                     },
                     {
                        path: 'invoice-setting',
                        name: 'setting-invoice-setting',
                        meta: {
                           permission: 'invoice_setting',
                           title: 'Invoice settings',
                           type: 'read',
                        },
                        component: () =>
                           import('@/views/modules/invoicing/views/settings/Setting.vue'),
                     },
                     {
                        path: 'unit',
                        name: 'unit',
                        meta: {
                           permission: 'units',
                           title: 'Unit Of Measure',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/common-settings/unit/List.vue'
                           ),
                     },
                     {
                        path: 'taxes',
                        name: 'taxes',
                        meta: {
                           permission: 'taxes',
                           title: 'Taxes',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/common-settings/taxes/List.vue'
                           ),
                     },
                     {
                        path: 'salesman',
                        name: 'salesman',
                        meta: {
                           permission: 'salesmen',
                           title: 'salesmans',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/common-settings/salesman/List.vue'
                           ),
                     },
                     {
                        path: 'client-group',
                        name: 'client-group',
                        meta: {
                           permission: 'client_groups',
                           title: 'Clients Groups',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/common-settings/client-group/List.vue'
                           ),
                     },
                     {
                        path: 'clients',
                        name: 'clients',
                        meta: {
                           permission: 'clients',
                           title: 'Clients',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/common-settings/client/List.vue'
                           ),
                     },
                     {
                        path: 'vendor-group',
                        name: 'vendor-group',
                        meta: {
                           permission: 'vendor_groups',
                           title: 'Vendor Groups',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/common-settings/vendor-group/List.vue'
                           ),
                     },
                     {
                        path: 'vendor',
                        name: 'vendor',
                        meta: {
                           permission: 'vendors',
                           title: 'Vendors',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/common-settings/vendor/List.vue'
                           ),
                     },
                  ],
               },
               {
                  path: 'account-settings',
                  name: 'account-settings',
                  component: () =>
                     import(
                        '@/views/modules/settings/views/settings/common-settings/invoice-settings.vue'
                     ),
                  children: [
                     {
                        path: 'mangement-settings-accounting',
                        name: 'mangement-settings-accounting',
                        meta: {
                           title: 'Accounting settings',
                           permission: 'accounting_dashboard',
                        },
                        component: () =>
                           import('@/views/modules/accounting/views/settings/Setting.vue'),
                     },
                     {
                        path: 'mangement-project-groups',
                        name: 'mangement-project-groups',
                        meta: {
                           permission: 'project_groups',
                           title: 'Project Groups',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/account-settings/project-group/List.vue'
                           ),
                     },
                     {
                        path: 'mangement-projects',
                        name: 'mangement-projects',
                        meta: {
                           permission: 'projects',
                           title: 'Projects',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/account-settings/projects/List.vue'
                           ),
                     },
                     {
                        path: 'mangement-journal-entries-list',
                        name: 'mangement-journal-entries-list',
                        meta: {
                           permission: 'account',
                           title: 'Chart Of Account',
                        },
                        component: () =>
                           import('@/views/modules/accounting/views/journal-entries/List.vue'),
                     },
                     {
                        path: 'mangement-journal-entries-view/:id',
                        name: 'mangement-journal-entries-view',
                        meta: {
                           permission: 'account',
                           title: 'Account',
                        },
                        component: () =>
                           import('@/views/modules/accounting/views/journal-entries/View.vue'),
                     },
                     {
                        path: 'mangement-account-groups',
                        name: 'mangement-account-groups',
                        meta: {
                           permission: 'account_groups',
                           title: 'Account Groups',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/account-settings/account-groups/List.vue'
                           ),
                     },
                     {
                        path: 'mangement-cost-center-groups',
                        name: 'mangement-cost-center-groups',
                        meta: {
                           permission: 'cost_center_groups',
                           title: 'Cost Center Groups',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/account-settings/cost-center-group/List.vue'
                           ),
                     },
                     {
                        path: 'mangement-cost-center',
                        name: 'mangement-cost-center',
                        meta: {
                           permission: 'cost_centers',
                           title: 'Cost Centers',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/account-settings/cost-center/List.vue'
                           ),
                     },
                     {
                        path: 'mangement-asset-groups',
                        name: 'mangement-asset-groups',
                        meta: {
                           permission: 'asset_groups',
                           title: 'Asset Groups',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/account-settings/asset-groups/List.vue'
                           ),
                     },
                     {
                        path: 'mangement-assets',
                        name: 'mangement-assets',
                        meta: {
                           permission: 'assets',
                           title: 'Assets',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/account-settings/assets/List.vue'
                           ),
                     },

                     {
                        path: 'journal-entry-template',
                        name: 'settings-accounting-journal-entry-template',
                        meta: {
                           permission: 'journal_entry_templates',
                           title: 'Periodical Journal Templates',
                        },
                        component: () =>
                           import(
                              '@/views/modules/accounting/views/daily-movements/journal-entry-template/List.vue'
                           ),
                     },
                     {
                        path: 'add-journal-entry-template',
                        name: 'settings-accounting-journal-entry-template-add',
                        meta: {
                           permission: 'journal_entry_templates',
                           title: 'Periodical Journal Templates',
                           type: 'write',
                        },
                        component: () =>
                           import(
                              '@/views/modules/accounting/views/daily-movements/journal-entry-template/Add.vue'
                           ),
                     },
                     {
                        path: 'update-journal-entry-template/:id',
                        name: 'settings-accounting-journal-entry-template-update',
                        meta: {
                           permission: 'journal_entry_templates',
                           title: 'Periodical Journal Templates',
                           type: 'write',
                        },
                        component: () =>
                           import(
                              '@/views/modules/accounting/views/daily-movements/journal-entry-template/Add.vue'
                           ),
                     },
                     {
                        path: 'update-journal-entry-template/:id/:view',
                        name: 'settings-accounting-journal-entry-template-view',
                        meta: {
                           permission: 'journal_entry_templates',
                           title: 'Periodical Journal Templates',
                        },
                        component: () =>
                           import(
                              '@/views/modules/accounting/views/daily-movements/journal-entry-template/Add.vue'
                           ),
                     },
                     {
                        path: 'journal-entry/add/:fromTemplate/:templateId',
                        name: 'settings-accounting-journal-entry-from-template-add',
                        meta: {
                           permission: 'journal_entries',
                           title: 'Journal Entry',
                           type: 'write',
                        },
                        component: () =>
                           import(
                              '@/views/modules/accounting/views/daily-movements/journal-entry/Add.vue'
                           ),
                     },
                  ],
               },
               {
                  path: 'salaries-settings',
                  name: 'salaries-settings',
                  component: () =>
                     import(
                        '@/views/modules/settings/views/settings/common-settings/invoice-settings.vue'
                     ),
                  children: [
                     {
                        path: 'general-settings',
                        name: 'settings-salaries-general-settings',
                        meta: {
                           permission: 'hr_setting',
                           title: 'HR and Payroll Settings',
                        },
                        component: () =>
                           import('@/views/modules/hr/views/settings/GeneralSettings/index.vue'),
                     },
                     {
                        path: 'mangement-spend-types',
                        name: 'magement-spend-types',
                        meta: {
                           permission: 'hr_spend_types',
                           title: 'Spend Types',
                        },
                        component: () =>
                           import('@/views/modules/salaries/views/crud/spend-types/List.vue'),
                     },
                     {
                        path: 'mangement-wage-items',
                        name: 'magement-wage-items',
                        meta: {
                           permission: 'hr_wage_items',
                           title: 'Wage Items',
                        },
                        component: () =>
                           import('@/views/modules/salaries/views/crud/wage-items/List.vue'),
                     },
                     {
                        path: 'mangement-wage-items-description',
                        name: 'magement-wage-items-description',
                        meta: {
                           permission: 'hr_wage_item_descriptions',
                           title: 'Wage Items Description',
                        },
                        component: () =>
                           import(
                              '@/views/modules/salaries/views/crud/wage-items-description/List.vue'
                           ),
                     },
                     {
                        path: 'salaries-dashboard',
                        name: 'salaries-dashboard',
                        meta: {
                           permission: 'hr_salaries_dashboard',
                           title: 'Payroll',
                        },
                        component: () =>
                           import('@/views/modules/salaries/views/dashboard/SalariesDashboard.vue'),
                     },
                  ],
               },
               {
                  path: 'attendance-settings',
                  name: 'attendance-settings',
                  component: () =>
                     import(
                        '@/views/modules/settings/views/settings/common-settings/invoice-settings.vue'
                     ),
                  children: [
                     {
                        path: 'attendance-settings',
                        name: 'settings-attendance-general-settings',
                        meta: {
                           title: 'attendanceSettings',
                        },
                        component: () =>
                           import(
                              '@/views/modules/attendance/views/settings/GeneralSettings/index.vue'
                           ),
                     },
                     {
                        path: 'mangement-attendance-dashboard',
                        name: 'mangement-attendance-dashboard',
                        component: () =>
                           import(
                              '@/views/modules/attendance/views/dashboard/AttendanceDashboard.vue'
                           ),
                     },
                     {
                        path: 'mangement-attendance-countries',
                        name: 'mangement-attendance-countries',
                        meta: {
                           permission: 'hr_countries',
                           title: 'Countries',
                        },
                        component: () => import('@/views/modules/hr/views/crud/countries/List.vue'),
                     },
                     {
                        path: 'mangement-attendance-languages',
                        name: 'mangement-attendance-languages',
                        meta: {
                           permission: 'hr_languages',
                           title: 'Languages',
                        },
                        component: () => import('@/views/modules/hr/views/crud/languages/List.vue'),
                     },
                     {
                        path: 'mangement-attendance-religions',
                        name: 'mangement-attendance-religions',
                        meta: {
                           permission: 'hr_religions',
                           title: 'Religions',
                        },
                        component: () => import('@/views/modules/hr/views/crud/religion/List.vue'),
                     },
                     {
                        path: 'mangement-attendance-nationalities',
                        name: 'mangement-attendance-nationalities',
                        meta: {
                           permission: 'nationalities',
                           title: 'Nationalities',
                        },
                        component: () =>
                           import('@/views/modules/hr/views/crud/nationality/List.vue'),
                     },
                     {
                        path: 'mangement-attendance-social-status',
                        name: 'mangement-attendance-social-status',
                        meta: {
                           permission: 'hr_marital_statuses',
                           title: 'Marital status',
                        },
                        component: () =>
                           import('@/views/modules/hr/views/crud/social-status/List.vue'),
                     },
                     {
                        path: 'mangement-attendance-departments',
                        name: 'mangement-attendance-departments',
                        meta: {
                           permission: 'hr_departments',
                           title: 'departments',
                        },
                        component: () =>
                           import('@/views/modules/hr/views/crud/departments/List.vue'),
                     },
                     {
                        path: 'mangement-attendance-job-types',
                        name: 'mangement-attendance-job-types',
                        meta: {
                           permission: 'hr_job_types',
                           title: 'Job Types',
                        },
                        component: () => import('@/views/modules/hr/views/crud/job-types/List.vue'),
                     },
                     {
                        path: 'mangement-attendance-jobs',
                        name: 'mangement-attendance-jobs',
                        meta: {
                           permission: 'hr_jobs',
                           title: 'Jobs',
                        },
                        component: () => import('@/views/modules/hr/views/crud/jobs/List.vue'),
                     },
                     {
                        path: 'mangement-attendance-work-locations',
                        name: 'mangement-attendance-work-locations',
                        meta: {
                           permission: 'hr_work_locations',
                           title: 'Work Locations',
                        },
                        component: () =>
                           import('@/views/modules/hr/views/crud/work-locations/List.vue'),
                     },
                     {
                        path: 'mangement-attendance-work-systems',
                        name: 'mangement-attendance-work-systems',
                        meta: {
                           permission: 'hr_work_systems',
                           title: 'Work systems',
                        },
                        component: () =>
                           import('@/views/modules/hr/views/crud/work-systems/List.vue'),
                     },

                     {
                        path: 'mangement-attendance-jobs-classifications',
                        name: 'mangement-attendance-jobs-classifications',
                        meta: {
                           permission: 'hr_job_classifications',
                           title: 'Jobs Classifications',
                        },
                        component: () =>
                           import('@/views/modules/hr/views/crud/jobs-classifications/List.vue'),
                     },
                     {
                        path: 'mangement-attendance-work-times',
                        name: 'mangement-attendance-work-times',
                        meta: {
                           permission: 'hr_work_times',
                           title: 'Work Times',
                        },
                        component: () =>
                           import('@/views/modules/attendance/views/settings/work-times/List.vue'),
                     },
                     {
                        path: 'mangement-attendance-work-times-schedules',
                        name: 'mangement-attendance-work-times-schedules',
                        meta: {
                           permission: 'hr_work_time_schedules',
                           title: 'workTimesSchedules',
                        },
                        component: () =>
                           import(
                              '@/views/modules/attendance/views/settings/work-times-schedules/List.vue'
                           ),
                     },

                     {
                        path: 'mangement-attendance-employers',
                        name: 'mangement-attendance-employers',
                        meta: {
                           permission: 'hr_employers',
                           title: 'Employers',
                        },
                        component: () => import('@/views/modules/hr/views/crud/employers/List.vue'),
                     },
                  ],
               },
               {
                  path: 'transport-settings',
                  name: 'transport-settings',
                  component: () =>
                     import(
                        '@/views/modules/settings/views/settings/common-settings/invoice-settings.vue'
                     ),
                  children: [
                     {
                        path: 'transport-dashboard',
                        name: 'mangement-transport-dashboard',
                        meta: {
                           title: 'Transportation',
                        },
                        component: () => import('@/views/modules/cargo/views/dashboard/Index.vue'),
                     },
                     {
                        path: 'settings-transportation',
                        name: 'mangement-settings-transportation',
                        meta: {
                           permission: 'transport_setting',
                           title: 'Transportation Settings',
                           // type: 'read',
                        },
                        component: () => import('@/views/modules/cargo/views/settings/Setting.vue'),
                     },
                     {
                        path: 'unit',
                        name: 'mangement-transportation-unit',
                        meta: {
                           permission: 'units',
                           title: 'Unit Of Measure',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/common-settings/unit/List.vue'
                           ),
                     },
                     {
                        path: 'taxes',
                        name: 'mangement-transportation-taxes',
                        meta: {
                           permission: 'taxes',
                           title: 'Taxes',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/common-settings/taxes/List.vue'
                           ),
                     },
                     {
                        path: 'salesman',
                        name: 'mangement-transportation-salesman',
                        meta: {
                           permission: 'salesmen',
                           title: 'salesmans',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/common-settings/salesman/List.vue'
                           ),
                     },
                     {
                        path: 'client-group',
                        name: 'mangement-transportation-client-group',
                        meta: {
                           permission: 'client_groups',
                           title: 'Clients Groups',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/common-settings/client-group/List.vue'
                           ),
                     },
                     {
                        path: 'clients',
                        name: 'mangement-transportation-clients',
                        meta: {
                           permission: 'clients',
                           title: 'Clients',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/common-settings/client/List.vue'
                           ),
                     },
                     {
                        path: 'transporter-group',
                        name: 'mangement-transportation-transporter-group',
                        meta: {
                           permission: 'vendor_groups',
                           title: 'Transporter Groups',
                        },
                        component: () =>
                           import('@/views/modules/cargo/views/crud/transporter-group/List.vue'),
                     },
                     {
                        path: 'transporter',
                        name: 'mangement-transportation-transporter',
                        meta: {
                           title: 'Transporters',
                        },
                        component: () =>
                           import('@/views/modules/cargo/views/crud/transporter/List.vue'),
                     },
                     {
                        path: 'product-categories',
                        name: 'mangement-transportation-product-categories',
                        meta: {
                           permission: 'product_categories',
                           title: 'Product Categories',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/common-settings/product-categories/List.vue'
                           ),
                     },
                     {
                        path: 'products',
                        name: 'mangement-transport-management-products',
                        meta: {
                           permission: 'products',
                           title: 'products',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/common-settings/products/main.vue'
                           ),
                        children: [
                           {
                              path: 'add-product',
                              name: 'transport-management-add-product',
                              meta: {
                                 permission: 'products',
                                 title: 'products',
                                 type: 'write',
                              },
                              component: () =>
                                 import(
                                    '@/views/modules/settings/views/settings/common-settings/products/Add.vue'
                                 ),
                           },
                           {
                              path: 'update-product/:id',
                              name: 'mangement-transport-management-update-product',
                              meta: {
                                 permission: 'products',
                                 title: 'products',
                                 type: 'write',
                              },
                              component: () =>
                                 import(
                                    '@/views/modules/settings/views/settings/common-settings/products/Add.vue'
                                 ),
                           },
                           {
                              path: 'view-product/:id',
                              name: 'mangement-transport-management-view-product',
                              meta: {
                                 permission: 'products',
                                 title: 'products',
                                 type: 'write',
                              },
                              component: () =>
                                 import(
                                    '@/views/modules/settings/views/settings/common-settings/products/Add.vue'
                                 ),
                           },
                        ],
                     },
                     {
                        path: 'model-settings',
                        name: 'settings-transport-model-settings',
                        meta: {
                           permission: 'transport_invoice_models',
                           title: 'Workflow Forms',
                           type: 'read',
                        },
                        component: () =>
                           import('@/views/modules/cargo/views/settings/invoice-model/List.vue'),
                     },
                     {
                        path: 'add-model-settings',
                        name: 'settings-transport-add-model-settings',
                        meta: {
                           permission: 'transport_invoice_models',
                           title: 'Workflow Forms',
                           type: 'write',
                        },
                        component: () =>
                           import('@/views/modules/cargo/views/settings/invoice-model/Add.vue'),
                     },
                     {
                        path: 'update-model-settings/:id',
                        name: 'settings-transport-update-model-settings',
                        props: true,
                        meta: {
                           permission: 'transport_invoice_models',
                           title: 'Workflow Forms',
                           type: 'write',
                        },
                        component: () =>
                           import('@/views/modules/cargo/views/settings/invoice-model/Add.vue'),
                     },
                     {
                        path: 'view-model-settings/:id',
                        name: 'settings-transport-view-model-settings',
                        props: true,
                        meta: {
                           permission: 'transport_invoice_models',
                           title: 'Workflow Forms',
                        },
                        component: () =>
                           import('@/views/modules/cargo/views/settings/invoice-model/Add.vue'),
                     },

                     {
                        path: 'transport-directions',
                        name: 'mangement-transport-directions',
                        meta: {
                           permission: 'transport_directions',
                           title: 'Directions',
                        },
                        component: () =>
                           import('@/views/modules/cargo/views/crud/directions/List.vue'),
                     },
                     {
                        path: 'transport-drivers',
                        name: 'mangement-transport-drivers',
                        meta: {
                           permission: 'transport_drivers',
                           title: 'Drivers',
                        },
                        component: () =>
                           import('@/views/modules/cargo/views/crud/drivers/List.vue'),
                     },
                     {
                        path: 'transport-trucks',
                        name: 'mangement-transport-trucks',
                        meta: {
                           permission: 'transport_trucks',
                           title: 'Trucks',
                        },
                        component: () => import('@/views/modules/cargo/views/crud/trucks/List.vue'),
                     },
                     {
                        path: 'transport-trailers',
                        name: 'mangement-transport-trailers',
                        meta: {
                           permission: 'transport_trailers',
                           title: 'Trailers',
                        },
                        component: () =>
                           import('@/views/modules/cargo/views/crud/trailers/List.vue'),
                     },
                     {
                        path: 'transport-cars',
                        name: 'mangement-transport-cars',
                        meta: {
                           permission: 'transport_cars',
                           title: 'Cars',
                        },
                        component: () => import('@/views/modules/cargo/views/crud/cars/List.vue'),
                     },
                     {
                        path: 'transport-car-expenses',
                        name: 'mangement-transport-car-expenses',
                        meta: {
                           permission: 'transport_car_expenses',
                           title: 'Cars Expenses',
                        },
                        component: () =>
                           import('@/views/modules/cargo/views/crud/car-expenses/List.vue'),
                     },
                  ],
               },
               {
                  path: 'customs-settings',
                  name: 'customs-settings',
                  component: () =>
                     import(
                        '@/views/modules/settings/views/settings/common-settings/invoice-settings.vue'
                     ),
                  children: [
                     {
                        path: 'mangement-customs-setting',
                        name: 'mangement-customs-settings-customs',
                        meta: {
                           permission: 'customs_setting',
                           title: 'Customs Settings',
                           type: 'read',
                        },
                        component: () =>
                           import('@/views/modules/customs/views/settings/Setting.vue'),
                     },
                     {
                        path: 'mangement-customs-unit',
                        name: 'mangement-customs-unit',
                        meta: {
                           permission: 'units',
                           title: 'Unit Of Measure',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/common-settings/unit/List.vue'
                           ),
                     },
                     {
                        path: 'mangement-customs-taxes',
                        name: 'mangement-customs-taxes',
                        meta: {
                           permission: 'taxes',
                           title: 'Taxes',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/common-settings/taxes/List.vue'
                           ),
                     },
                     {
                        path: 'mangement-customs-agent',
                        name: 'mangement-customs-agent',
                        meta: {
                           permission: 'customs_agents',
                           title: 'Agents',
                        },
                        component: () =>
                           import('@/views/modules/customs/views/crud/agent/List.vue'),
                     },
                     {
                        path: 'nationalities',
                        name: 'mangement-customs-nationalities',
                        meta: {
                           permission: 'nationalities',
                           title: 'Nationalities',
                        },
                        component: () =>
                           import('@/views/modules/hr/views/crud/nationality/List.vue'),
                     },
                     {
                        path: 'mangement-customs-transactions',
                        name: 'mangement-customs-transactions',
                        meta: {
                           title: 'customTransactions',
                        },
                        component: () =>
                           import(
                              '@/views/modules/customs/views/daily-movements/transactions/List.vue'
                           ),
                     },
                     {
                        path: 'mangement-customs-brokers',
                        name: 'mangement-customs-brokers',
                        meta: {
                           permission: 'customs_brokers',
                           title: 'customsBrokers',
                        },
                        component: () =>
                           import('@/views/modules/customs/views/daily-movements/brokers/List.vue'),
                     },
                     {
                        path: 'mangement-customs-containers',
                        name: 'mangement-customs-containers',
                        meta: {
                           permission: 'customs_containers',
                           title: 'containers',
                        },
                        component: () =>
                           import(
                              '@/views/modules/customs/views/daily-movements/containers/List.vue'
                           ),
                     },
                     {
                        path: 'mangement-customs-documents',
                        name: 'mangement-customs-documents',
                        meta: {
                           permission: 'customs_documents',
                           title: 'Documents',
                        },
                        component: () =>
                           import(
                              '@/views/modules/customs/views/daily-movements/documents/List.vue'
                           ),
                     },
                     {
                        path: 'mangement-customs-invoice-items',
                        name: 'mangement-customs-invoice-items',
                        meta: {
                           permission: 'customs_invoice_items',
                           title: 'invoiceItems',
                        },
                        component: () =>
                           import(
                              '@/views/modules/customs/views/daily-movements/invoice-items/List.vue'
                           ),
                     },
                     {
                        path: 'mangement-customs-expenses-incomes',
                        name: 'mangement-customs-expenses-incomes',
                        meta: {
                           permission: 'customs_expense_revenues',
                           title: 'expensesAndIncomes',
                        },
                        component: () =>
                           import(
                              '@/views/modules/customs/views/daily-movements/expenses-incomes/List.vue'
                           ),
                     },
                     {
                        path: 'mangement-customs-client-group',
                        name: 'mangement-customs-client-group',
                        meta: {
                           permission: 'client_groups',
                           title: 'Clients Groups',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/common-settings/client-group/List.vue'
                           ),
                     },
                     {
                        path: 'mangement-customs-clients',
                        name: 'mangement-customs-clients',
                        meta: {
                           permission: 'clients',
                           title: 'Clients',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/common-settings/client/List.vue'
                           ),
                     },
                     {
                        path: 'mangement-customs-vendor-group',
                        name: 'mangement-customs-vendor-group',
                        meta: {
                           permission: 'vendor_groups',
                           title: 'Vendor Groups',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/common-settings/vendor-group/List.vue'
                           ),
                     },
                     {
                        path: 'mangement-customs-vendor',
                        name: 'mangement-customs-vendor',
                        meta: {
                           permission: 'vendors',
                           title: 'Vendors',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/common-settings/vendor/List.vue'
                           ),
                     },
                     {
                        path: 'mangement-customs-product-categories',
                        name: 'mangement-customs-product-categories',
                        meta: {
                           permission: 'product_categories',
                           title: 'Product Categories',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/common-settings/product-categories/List.vue'
                           ),
                     },
                     {
                        path: 'mangement-customs-model-settings',
                        name: 'settings-customs-model-settings',
                        meta: {
                           permission: 'invoice_models',
                           title: 'Workflow Forms',
                           type: 'read',
                        },
                        component: () =>
                           import('@/views/modules/customs/views/settings/invoice-model/List.vue'),
                     },
                     {
                        path: 'mangement-customs-add-model-settings',
                        name: 'settings-customs-add-model-settings',
                        meta: {
                           permission: 'invoice_models',
                           title: 'Workflow Forms',
                           type: 'write',
                        },
                        component: () =>
                           import('@/views/modules/customs/views/settings/invoice-model/Add.vue'),
                     },
                     {
                        path: 'mangement-customs-update-model-settings/:id',
                        name: 'settings-customs-update-model-settings',
                        props: true,
                        meta: {
                           permission: 'invoice_models',
                           title: 'Workflow Forms',
                           type: 'write',
                        },
                        component: () =>
                           import('@/views/modules/customs/views/settings/invoice-model/Add.vue'),
                     },
                     {
                        path: 'mangement-customs-view-model-settings/:id',
                        name: 'settings-customs-view-model-settings',
                        props: true,
                        meta: {
                           permission: '',
                           title: 'Workflow Forms',
                        },
                        component: () =>
                           import('@/views/modules/customs/views/settings/invoice-model/Add.vue'),
                     },
                     {
                        path: 'mangement-customs-services',
                        name: 'settings-customs-services',
                        meta: {
                           permission: 'customs_services',
                           title: 'Services',
                        },
                        component: () =>
                           import('@/views/modules/customs/views/crud/products/List.vue'),
                     },
                     {
                        path: 'mangement-customs-add-service',
                        name: 'settings-add-customs-service',
                        meta: {
                           permission: 'customs_services',
                           title: 'Services',
                           type: 'write',
                        },
                        component: () =>
                           import('@/views/modules/customs/views/crud/products/Add.vue'),
                     },
                     {
                        path: 'mangement-customs-update-service/:id',
                        name: 'settings-update-customs-service',
                        meta: {
                           permission: 'customs_services',
                           title: 'Services',
                           type: 'write',
                        },
                        component: () =>
                           import('@/views/modules/customs/views/crud/products/Add.vue'),
                     },
                     {
                        path: 'mangement-customs-view-service/:id/:view',
                        name: 'settings-view-customs-service',
                        meta: {
                           permission: 'customs_services',
                           title: 'Services',
                           type: 'write',
                        },
                        component: () =>
                           import('@/views/modules/customs/views/crud/products/Add.vue'),
                     },
                  ],
               },
               {
                  path: 'hr-settings',
                  name: 'hr-settings',
                  component: () =>
                     import(
                        '@/views/modules/settings/views/settings/common-settings/invoice-settings.vue'
                     ),
                  children: [
                     {
                        path: 'mangement-general-settings',
                        name: 'mangement-general-settings',
                        component: () =>
                           import('@/views/modules/hr/views/settings/GeneralSettings/index.vue'),
                     },
                     {
                        path: 'mangement-trainings-settings',
                        name: 'mangement-trainings-settings',
                        meta: {
                           permission: 'hr_trainings',
                           title: 'trainingsSettings',
                        },
                        component: () => import('@/views/modules/hr/views/crud/trainings/List.vue'),
                     },
                     {
                        path: 'mangement-trainers-settings',
                        name: 'mangement-trainers-settings',
                        meta: {
                           permission: 'hr_trainers',
                           title: 'trainersSettings',
                        },
                        component: () => import('@/views/modules/hr/views/crud/trainers/List.vue'),
                     },
                     {
                        path: 'mangement-hr-dashboard',
                        name: 'mangement-dashboard',
                        meta: {
                           permission: 'hr_dashboard',
                           title: 'HR',
                        },
                        component: () =>
                           import('@/views/modules/hr/views/dashboard/HrDashboard.vue'),
                     },
                     {
                        path: 'mangement-work-systems',
                        name: 'mangement-work-systems',
                        meta: {
                           permission: 'hr_work_systems',
                           title: 'Work systems',
                        },
                        component: () =>
                           import('@/views/modules/hr/views/crud/work-systems/List.vue'),
                     },
                     {
                        path: 'mangement-sanctions-reasons',
                        name: 'mangement-sanctions-reasons',
                        meta: {
                           permission: 'hr_sanction_reasons',
                           title: 'Sanctions Reasons',
                        },
                        component: () =>
                           import('@/views/modules/hr/views/crud/sanctions-reasons/List.vue'),
                     },
                     {
                        path: 'mangement-job-types',
                        name: 'mangement-job-types',
                        meta: {
                           permission: 'hr_job_types',
                           title: 'Job Types',
                        },
                        component: () => import('@/views/modules/hr/views/crud/job-types/List.vue'),
                     },
                     {
                        path: 'mangement-dismisses',
                        name: 'mangement-dismisses',
                        meta: {
                           permission: 'hr_tellers',
                           title: 'Salary Cashing entities',
                        },
                        component: () => import('@/views/modules/hr/views/crud/dismisses/List.vue'),
                     },
                     {
                        path: 'mangement-jobs',
                        name: 'mangement-jobs',
                        meta: {
                           permission: 'hr_jobs',
                           title: 'Jobs',
                        },
                        component: () => import('@/views/modules/hr/views/crud/jobs/List.vue'),
                     },
                     {
                        path: 'mangement-jobs-classifications',
                        name: 'mangement-jobs-classifications',
                        meta: {
                           permission: 'hr_job_classifications',
                           title: 'Jobs Classifications',
                        },
                        component: () =>
                           import('@/views/modules/hr/views/crud/jobs-classifications/List.vue'),
                     },
                     {
                        path: 'mangement-work-locations',
                        name: 'mangement-work-locations',
                        meta: {
                           permission: 'hr_work_locations',
                           title: 'Work Locations',
                        },
                        component: () =>
                           import('@/views/modules/hr/views/crud/work-locations/List.vue'),
                     },
                     {
                        path: 'mangement-evaluation-items',
                        name: 'mangement-evaluation-items',
                        meta: {
                           permission: 'hr_evaluation_items',
                           title: 'Evaluation Items',
                        },
                        component: () =>
                           import('@/views/modules/hr/views/crud/evaluation-items/List.vue'),
                     },
                     {
                        path: 'mangement-departments',
                        name: 'mangement-departments',
                        meta: {
                           permission: 'hr_departments',
                           title: 'departments',
                        },
                        component: () =>
                           import('@/views/modules/hr/views/crud/departments/List.vue'),
                     },
                     {
                        path: 'mangement-custodies',
                        name: 'mangement-custodies',
                        meta: {
                           permission: 'hr_custodies',
                           title: 'Custodies',
                        },
                        component: () => import('@/views/modules/hr/views/crud/custodies/List.vue'),
                     },
                     {
                        path: 'mangement-sanctions',
                        name: 'mangement-sanctions',
                        meta: {
                           permission: 'hr_sanctions',
                           title: 'Sanctions',
                        },
                        component: () => import('@/views/modules/hr/views/crud/sanctions/List.vue'),
                     },
                     {
                        path: 'mangement-employers',
                        name: 'mangement-employers',
                        meta: {
                           permission: 'hr_employers',
                           title: 'Employers',
                        },
                        component: () => import('@/views/modules/hr/views/crud/employers/List.vue'),
                     },
                     {
                        path: 'mangement-countries',
                        name: 'mangement-countries',
                        meta: {
                           permission: 'hr_countries',
                           title: 'Countries',
                        },
                        component: () => import('@/views/modules/hr/views/crud/countries/List.vue'),
                     },
                     {
                        path: 'mangement-languages',
                        name: 'mangement-languages',
                        meta: {
                           permission: 'hr_languages',
                           title: 'Languages',
                        },
                        component: () => import('@/views/modules/hr/views/crud/languages/List.vue'),
                     },
                     {
                        path: 'mangement-nationalities',
                        name: 'mangement-nationalities',
                        meta: {
                           permission: 'nationalities',
                           title: 'Nationalities',
                        },
                        component: () =>
                           import('@/views/modules/hr/views/crud/nationality/List.vue'),
                     },
                     {
                        path: 'mangement-religions',
                        name: 'mangement-religions',
                        meta: {
                           permission: 'hr_religions',
                           title: 'Religions',
                        },
                        component: () => import('@/views/modules/hr/views/crud/religion/List.vue'),
                     },
                     {
                        path: 'mangement-educational-qualifications',
                        name: 'mangement-educational-qualifications',
                        meta: {
                           permission: 'hr_educational_qualifications',
                           title: 'Educational Qualifications',
                        },
                        component: () =>
                           import(
                              '@/views/modules/hr/views/crud/educational-qualifications/List.vue'
                           ),
                     },
                     {
                        path: 'mangement-social-status',
                        name: 'mangement-social-status',
                        meta: {
                           permission: 'hr_marital_statuses',
                           title: 'Marital status',
                        },
                        component: () =>
                           import('@/views/modules/hr/views/crud/social-status/List.vue'),
                     },
                     {
                        path: 'mangement-educational-bodies',
                        name: 'mangement-educational-bodies',
                        meta: {
                           permission: 'hr_educational_institutions',
                           title: 'Educational Bodies',
                        },
                        component: () =>
                           import('@/views/modules/hr/views/crud/educational-bodies/List.vue'),
                     },
                     {
                        path: 'mangement-specializations',
                        name: 'mangement-specializations',
                        meta: {
                           permission: 'hr_specialties',
                           title: 'Specializations',
                        },
                        component: () =>
                           import('@/views/modules/hr/views/crud/specializations/List.vue'),
                     },
                     {
                        path: 'mangement-documents',
                        name: 'mangement-documents',
                        meta: {
                           permission: 'hr_documents',
                           title: 'Documents',
                        },
                        component: () => import('@/views/modules/hr/views/crud/document/List.vue'),
                     },
                     {
                        path: 'mangement-appreciations',
                        name: 'mangement-appreciations',
                        meta: {
                           permission: 'hr_appreciations',
                           title: 'Appreciations',
                        },
                        component: () =>
                           import('@/views/modules/hr/views/crud/appreciations/List.vue'),
                     },
                     {
                        path: 'general-settings',
                        name: 'settings-hr-general-settings',
                        component: () =>
                           import('@/views/modules/hr/views/settings/GeneralSettings/index.vue'),
                     },
                  ],
               },
               {
                  path: 'general-settings',
                  name: 'general-settings',
                  meta: {
                     title: 'generalSettings',
                  },
                  component: () =>
                     import('@/views/modules/settings/views/settings/general-settings/index.vue'),
                  children: [
                     {
                        path: '',
                        name: 'general-settings-base',

                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/general-settings/general-settings.vue'
                           ),
                     },
                     {
                        path: 'company-info',
                        name: 'company-info',
                        meta: {
                           permission: 'company',
                           title: 'Company Info',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/general-settings/company-info/company-info.vue'
                           ),
                     },
                     {
                        path: 'switch-language',
                        name: 'switch-language',
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/general-settings/switch-language/index.vue'
                           ),
                     },
                     {
                        path: 'financial-year',
                        name: 'financial-year',
                        meta: {
                           permission: 'financial_years',
                           title: 'Fiscal Year',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/general-settings/financial-year/List.vue'
                           ),
                     },
                     {
                        path: 'company-documents',
                        name: 'company-documents',
                        meta: {
                           permission: 'company_documents',
                           title: 'Company Documents',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/general-settings/company-documents/List.vue'
                           ),
                     },
                     {
                        path: 'currencies',
                        name: 'currencies',
                        meta: {
                           permission: 'currencies',
                           title: 'Currencies',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/general-settings/currencies/List.vue'
                           ),
                     },
                     {
                        path: 'branches',
                        name: 'branches',
                        meta: {
                           permission: 'branches',
                           title: 'Branches',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/general-settings/branches/List.vue'
                           ),
                     },
                     {
                        path: 'banks',
                        name: 'banks',
                        meta: {
                           permission: 'banks',
                           title: 'Banks',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/general-settings/banks/List.vue'
                           ),
                     },
                     {
                        path: 'cards',
                        name: 'cards',
                        meta: {
                           permission: 'cards',
                           title: 'Cards',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/general-settings/cards/List.vue'
                           ),
                     },
                     {
                        path: 'users',
                        name: 'users',
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/general-settings/users-and-permissions/main.vue'
                           ),
                        meta: {
                           permission: 'users',
                           title: 'Users',
                        },
                        children: [
                           {
                              path: 'list',
                              name: 'user-list',
                              meta: {
                                 permission: 'users',
                                 title: 'Users',
                              },
                              component: () =>
                                 import(
                                    '@/views/modules/settings/views/settings/general-settings/users-and-permissions/users/List.vue'
                                 ),
                           },
                        ],
                     },
                     {
                        path: 'permissions',
                        name: 'permissions',
                        meta: {
                           permission: 'permissions',
                           title: 'Permissions',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/general-settings/users-and-permissions/permissions/List.vue'
                           ),
                     },
                     {
                        path: 'update-permission/:id',
                        name: 'update-permission',
                        props: true,
                        meta: {
                           permission: 'permissions',
                           title: 'Permissions',
                           type: 'write',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/general-settings/users-and-permissions/permissions/add-permission.vue'
                           ),
                     },
                     {
                        path: 'add-permission',
                        name: 'add-permission',
                        meta: {
                           permission: 'permissions',
                           title: 'Permissions',
                           type: 'write',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/general-settings/users-and-permissions/permissions/add-permission.vue'
                           ),
                     },
                     {
                        path: 'printmodels',
                        name: 'printmodels',
                        meta: {
                           title: 'print models',
                           role: 'owner',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/general-settings/print-models/List.vue'
                           ),
                     },
                     {
                        path: 'printmodels/view/:id',
                        name: 'view-print-model',
                        props: true,
                        meta: {
                           title: 'View Print Model',
                           role: 'owner',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/general-settings/print-models/View.vue'
                           ),
                     },
                     {
                        path: 'printmodels/edit/:id',
                        name: 'edit-print-model',
                        props: true,
                        meta: {
                           title: 'Edit Print Models',
                           role: 'owner',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/general-settings/print-models/Edit.vue'
                           ),
                     },
                  ],
               },
               {
                  path: 'electronic-settings',
                  name: 'electronic-settings',
                  component: () =>
                     import(
                        '@/views/modules/settings/views/settings/common-settings/invoice-settings.vue'
                     ),
                  children: [...electronicApprovalsGeneralRouter],
               },
               {
                  path: 'assets-settings',
                  name: 'assets-setting',
                  component: () =>
                     import(
                        '@/views/modules/settings/views/settings/common-settings/invoice-settings.vue'
                     ),
                  children: [...assetsGeneralRouter],
               },
               {
                  path: 'pos-settings',
                  name: 'pos-setting',
                  component: () =>
                     import(
                        '@/views/modules/settings/views/settings/common-settings/invoice-settings.vue'
                     ),
                  children: [...posGeneralRouter],
               },
            ],
         },
         {
            path: '/hr-management',
            name: 'hr-management',
            redirect: '/hr-management/hr-dashboard',
            component: () => import('@/views/modules/hr/base.vue'),
            children: [...hrRouters],
         },
         {
            path: '/salaries-management',
            name: 'salaries-management',
            redirect: '/salaries-management/salaries-dashboard',
            component: () => import('@/views/modules/salaries/base.vue'),
            children: [...SalariesRouters],
         },
         {
            path: '/attendance-management',
            name: 'attendance-management',
            redirect:
               (AuthService.hasRole('employee') && store.getters.currentUser.roles.length > 1) ||
               !AuthService.hasRole('employee')
                  ? '/attendance-management/attendance-dashboard'
                  : '/attendance-management/daily-transactions/leave-request',
            component: () => import('@/views/modules/attendance/base.vue'),
            children: [...AttendanceRouters],
         },
         {
            path: '/customs-management',
            name: 'customs-management',
            redirect: '/customs-management/customs-dashboard',
            component: () => import('@/views/modules/customs/base.vue'),
            children: [...CustomsRouters],
         },
         {
            path: '/accounting-management',
            name: 'accounting-management',
            redirect: '/accounting-management/accounting-dashboard',
            component: () => import('@/views/modules/accounting/base.vue'),
            children: [...accountingRouters],
         },
         {
            path: '/transport-management',
            name: 'transport-management',
            redirect: '/transport-management/transport-dashboard',
            component: () => import('@/views/modules/cargo/base.vue'),
            children: [...cargoRouters],
         },
         {
            path: '/warehouse-management',
            name: 'warehouse-management',
            redirect: '/warehouse-management/warehouse-dashboard',
            component: () => import('@/views/modules/warehouse/base.vue'),
            children: [
               ...warehouseRouters,
               {
                  path: 'warehouse-journal-posting',
                  name: 'warehouse-journal-posting',
                  meta: {
                     title: 'Post to Accounting',
                     // permission: 'journal_posting',
                  },
                  component: () =>
                     import('@/views/modules/warehouse/views/settings/journal-posting/base.vue'),
               },
               {
                  path: 'stocktaking',
                  name: 'warehouse-stocktaking-list',
                  meta: {
                     permission: 'stocktaking',
                     title: 'Stock adjustments',
                  },
                  component: () => import('@/views/modules/warehouse/views/stocktaking/List.vue'),
               },
               {
                  path: 'stocktaking/add',
                  name: 'add-warehouse-stocktaking',
                  meta: {
                     permission: 'stocktaking',
                     type: 'write',
                     title: 'Stock adjustments',
                  },
                  component: () => import('@/views/modules/warehouse/views/stocktaking/Add.vue'),
               },
               {
                  path: 'update-warehouse-stocktaking/:id/:type',
                  name: 'update-warehouse-stocktaking',
                  props: true,
                  meta: {
                     permission: 'stocktaking',
                     type: '',
                     title: 'Stock adjustments',
                  },
                  component: () => import('@/views/modules/warehouse/views/stocktaking/Add.vue'),
               },
               {
                  name: 'warehouse-transferring-log',
                  path: 'transferring-log',
                  meta: {
                     title: 'Posting Log',
                  },
                  component: () => import('@/views/modules/shared/transferring-log/List.vue'),
               },
            ],
         },
         {
            path: '/invoicing-management',
            name: 'invoicing',
            redirect: '/invoicing-management/dashboard',

            component: () => import('@/views/modules/invoicing/base.vue'),
            children: [
               {
                  path: 'invoicing-journal-posting',
                  name: 'invoicing-journal-posting',
                  meta: {
                     title: 'Post to Accounting',
                  },
                  component: () =>
                     import('@/views/modules/invoicing/views/settings/journal-posting/base.vue'),
               },
               {
                  path: 'reports',
                  name: 'reports',

                  children: [
                     {
                        path: 'overall-detailed-reports',
                        name: 'overall-detailed-reports',
                        meta: {
                           title: 'Sales Transaction',
                        },
                        component: () =>
                           import(
                              '@/views/modules/invoicing/views/settings/reports/overall-detailed-reports/base.vue'
                           ),
                     },
                     {
                        path: 'overall-detailed-reports',
                        name: 'overall-detailed-reports',
                        meta: {
                           title: 'Sales Transaction',
                           permission: 'invoicing_reports',
                        },
                        component: () =>
                           import(
                              '@/views/modules/invoicing/views/settings/reports/overall-detailed-reports/base.vue'
                           ),
                     },
                     {
                        path: 'clients-transaction-report',
                        name: 'clients-transaction-report',
                        meta: {
                           title: 'Client/Products Transaction',
                           permission: 'invoicing_reports',
                        },
                        component: () =>
                           import(
                              '@/views/modules/invoicing/views/settings/reports/client-transactions-reports/base.vue'
                           ),
                     },
                     {
                        path: 'overall-model-report',
                        name: 'overall-model-report',
                        meta: {
                           title: 'Models',
                           permission: 'invoicing_reports',
                        },
                        component: () =>
                           import(
                              '@/views/modules/invoicing/views/settings/reports/overall-model-reports/base.vue'
                           ),
                     },
                     {
                        path: 'tax-declaration-report',
                        name: 'tax-declaration-report',
                        meta: {
                           title: 'Tax Declaration',
                           permission: 'invoicing_reports',
                        },
                        component: () =>
                           import(
                              '@/views/modules/warehouse/views/report/common/tax-declaration-report/Index.vue'
                           ),
                     },
                     {
                        path: 'product-sales-report',
                        name: 'product-sales-report',
                        meta: {
                           title: 'Product Sales',
                           permission: 'invoicing_reports',
                        },
                        component: () =>
                           import(
                              '@/views/modules/invoicing/views/settings/reports/product-sales-report/base.vue'
                           ),
                     },
                     {
                        path: 'best-selling-customers-report',
                        name: 'best-selling-customers-report',
                        meta: {
                           title: 'Best Selling Customers',
                           permission: 'invoicing_reports',
                        },
                        component: () =>
                           import(
                              '@/views/modules/invoicing/views/settings/reports/best-selling-customers-report/base.vue'
                           ),
                     },
                     {
                        path: 'best-selling-products-report',
                        name: 'best-selling-products-report',
                        meta: {
                           title: 'Best Selling Products',
                           permission: 'invoicing_reports',
                        },
                        component: () =>
                           import(
                              '@/views/modules/invoicing/views/settings/reports/best-selling-product-report/base.vue'
                           ),
                     },
                     {
                        path: 'sales-business-transactions-report',
                        name: 'sales-business-transactions-report',
                        meta: {
                           title: 'Sales with tax',
                           permission: 'invoicing_reports',
                        },
                        component: () =>
                           import(
                              '@/views/modules/invoicing/views/settings/reports/sales-business-movement-report/base.vue'
                           ),
                     },
                     {
                        path: 'customers-info',
                        name: 'sales-customers-info-report',
                        meta: {
                           title: 'Customers info',
                           permission: 'invoicing_reports',
                        },
                        component: () =>
                           import(
                              '@/views/modules/invoicing/views/settings/reports/customers-info-report/base.vue'
                           ),
                     },
                     {
                        path: 'client-account-statement-report',
                        name: 'client-account-statement-report',
                        meta: {
                           title: 'Clients Account Statement',
                           permission: 'invoicing_reports',
                        },
                        component: () =>
                           import(
                              '@/views/modules/invoicing/views/settings/reports/client-account-statement-report/base.vue'
                           ),
                     },
                     {
                        path: 'customer-debt-aging-report',
                        name: 'customer-debt-aging-report',
                        meta: {
                           title: 'Customer Debt Aging',
                           permission: 'invoicing_reports',
                        },
                        component: () =>
                           import(
                              '@/views/modules/invoicing/views/settings/reports/customer-debt-aging-report/base.vue'
                           ),
                     },
                     {
                        path: 'clients-groups-details-transactions-report',
                        name: 'clients-groups-details-transactions-report',
                        meta: {
                           title: 'Clients Groups Sales Transactions',
                           permission: 'invoicing_reports',
                        },
                        component: () =>
                           import(
                              '@/views/modules/invoicing/views/settings/reports/clients-groups-details-transactions-report/base.vue'
                           ),
                     },
                     {
                        path: 'customer-trial-balance-report',
                        name: 'customer-trial-balance-report',
                        meta: {
                           title: 'Customer Trial Balance',
                           permission: 'invoicing_reports',
                        },
                        component: () =>
                           import(
                              '@/views/modules/invoicing/views/settings/reports/customer-trial-balance-report/base.vue'
                           ),
                     },
                     {
                        path: 'salesman-sales-transactions-report',
                        name: 'salesman-sales-transactions-report',
                        meta: {
                           title: 'Sales of representatives',
                           permission: 'invoicing_reports',
                        },
                        component: () =>
                           import(
                              '@/views/modules/invoicing/views/settings/reports/salesman-sales-transactions-reports/base.vue'
                           ),
                     },
                     {
                        path: 'branch-sales-report',
                        name: 'branch-sales-report',
                        meta: {
                           title: 'Branch Sales Transaction',
                           permission: 'invoicing_reports',
                        },
                        component: () =>
                           import(
                              '@/views/modules/invoicing/views/settings/reports/branch-sales-transaction/base.vue'
                           ),
                     },
                     {
                        path: 'sales-performance-evaluation-report',
                        name: 'sales-performance-evaluation-report',
                        meta: {
                           title: 'Sales Performance Evaluation',
                           permission: 'invoicing_reports',
                        },
                        component: () =>
                           import(
                              '@/views/modules/invoicing/views/settings/reports/sales-performance-evaluation-reports/base.vue'
                           ),
                     },
                     {
                        path: 'salesman-commissions',
                        name: 'salesman-commissions',
                        meta: {
                           title: 'Representatives Commissions',
                           permission: 'invoicing_reports',
                        },
                        component: () =>
                           import(
                              '@/views/modules/invoicing/views/settings/reports/salesman-commissions/base.vue'
                           ),
                     },
                  ],
               },
               {
                  path: '/invoicing-management/dashboard',
                  name: 'invoicing-management-dashboard',
                  meta: {
                     title: 'Invoicing',
                  },
                  component: () => import('@/views/modules/invoicing/views/InvoiceDashboard.vue'),
               },
               {
                  path: 'commission-policy',
                  name: 'invoicing-management-commission-policy',
                  meta: {
                     permission: 'commission_policies',
                     title: 'Commission Policy',
                  },
                  component: () =>
                     import(
                        '@/views/modules/invoicing/views/settings/commission-policy/Settings.vue'
                     ),
               },
               {
                  path: 'model-settings',
                  name: 'invoicing-management-model-settings',
                  meta: {
                     permission: 'invoice_models',
                     title: 'Workflow Forms',
                     type: 'read',
                  },
                  component: () =>
                     import('@/views/modules/invoicing/views/settings/invoice-model/List.vue'),
               },
               {
                  path: 'add-model-settings',
                  name: 'invoicing-management-add-model-settings',
                  meta: {
                     permission: 'invoice_models',
                     title: 'Workflow Forms',
                     type: 'write',
                  },
                  component: () =>
                     import('@/views/modules/invoicing/views/settings/invoice-model/Add.vue'),
               },
               {
                  path: 'add-model-settings/duplicate/:duplicationId',
                  name: 'invoicing-management-add-duplicate-model-settings',
                  meta: {
                     permission: 'invoice_models',
                     title: 'Workflow Forms',
                     type: 'write',
                  },
                  component: () =>
                     import('@/views/modules/invoicing/views/settings/invoice-model/Add.vue'),
               },
               {
                  path: 'update-model-settings/:id',
                  name: 'invoicing-management-update-model-settings',
                  props: true,
                  meta: {
                     permission: 'invoice_models',
                     title: 'Workflow Forms',
                     type: 'write',
                  },
                  component: () =>
                     import('@/views/modules/invoicing/views/settings/invoice-model/Add.vue'),
               },
               {
                  path: 'view-model-settings/:id',
                  name: 'invoicing-management-view-model-settings',
                  props: true,
                  meta: {
                     permission: '',
                     title: 'Workflow Forms',
                  },
                  component: () =>
                     import('@/views/modules/invoicing/views/settings/invoice-model/Add.vue'),
               },
               {
                  path: 'sales-financial-transactions',
                  name: 'invoicing-management-sales-financial-transactions',
                  meta: {
                     title: 'Financial Transactions',
                  },
                  component: () =>
                     import(
                        '@/views/modules/invoicing/views/settings/financial-transactions/sales/List.vue'
                     ),
               },
               {
                  path: 'add-sales-financial-transactions',
                  name: 'add-sales-financial-transactions',
                  meta: {
                     title: 'Financial Transactions',
                  },
                  component: () =>
                     import(
                        '@/views/modules/invoicing/views/settings/financial-transactions/sales/Add.vue'
                     ),
               },
               {
                  path: 'update-sales-financial-transactions/:id',
                  name: 'update-sales-financial-transactions',
                  meta: {
                     title: 'Financial Transactions',
                  },
                  component: () =>
                     import(
                        '@/views/modules/invoicing/views/settings/financial-transactions/sales/Add.vue'
                     ),
               },
               {
                  path: 'view-sales-financial-transactions/:id/:type',
                  name: 'view-sales-financial-transactions',
                  meta: {
                     title: 'Financial Transactions',
                  },
                  component: () =>
                     import(
                        '@/views/modules/invoicing/views/settings/financial-transactions/sales/Add.vue'
                     ),
               },
               {
                  path: 'sales-invoices/edit/:modelId/:invoiceId',
                  name: 'invoicing-management-edit-sales-invoice',
                  meta: {
                     permission: 'sales_daily_movements',
                     title: 'sales daily transactions',
                     type: 'write',
                  },
                  component: () =>
                     import('@/views/modules/invoicing/views/settings/invoices/sales/Add.vue'),
               },
               {
                  path: 'sales-invoices/:type/:modelId/:invoiceId',
                  name: 'invoicing-management-view-sales-invoice',
                  meta: {
                     permission: 'sales_daily_movements',
                     title: 'sales daily transactions',
                  },
                  component: () =>
                     import('@/views/modules/invoicing/views/settings/invoices/sales/Add.vue'),
               },
               {
                  path: 'sales-invoices/add/:modelId',
                  name: 'invoicing-management-add-sales-invoice',
                  meta: {
                     permission: 'sales_daily_movements',
                     title: 'sales daily transactions',
                     type: 'write',
                  },
                  component: () =>
                     import('@/views/modules/invoicing/views/settings/invoices/sales/Add.vue'),
               },
               {
                  path: 'sales-invoices/add/:modelId/duplicate/:duplicationId',
                  name: 'invoicing-management-add-sales-duplicate-invoice',
                  meta: {
                     permission: 'sales_daily_movements',
                     title: 'sales daily transactions',
                     type: 'write',
                  },
                  component: () =>
                     import('@/views/modules/invoicing/views/settings/invoices/sales/Add.vue'),
               },
               {
                  path: 'sales-invoices/list/:modelId',
                  name: 'invoicing-management-sales-invoices',
                  meta: {
                     permission: 'sales_daily_movements',
                     title: 'sales daily transactions',
                     type: 'read',
                  },
                  component: () =>
                     import('@/views/modules/invoicing/views/settings/invoices/sales/List.vue'),
               },
               {
                  path: 'setting',
                  name: 'settings-invoicing',
                  meta: {
                     permission: 'invoice_setting',
                     title: 'Invoice settings',
                     type: 'read',
                  },
                  component: () => import('@/views/modules/invoicing/views/settings/Setting.vue'),
               },
               {
                  path: 'unit',
                  name: 'invoice-unit',
                  meta: {
                     permission: 'units',
                     title: 'Unit Of Measure',
                  },
                  component: () =>
                     import(
                        '@/views/modules/settings/views/settings/common-settings/unit/List.vue'
                     ),
               },
               {
                  path: 'taxes',
                  name: 'invoice-taxes',
                  meta: {
                     permission: 'taxes',
                     title: 'Taxes',
                  },
                  component: () =>
                     import(
                        '@/views/modules/settings/views/settings/common-settings/taxes/List.vue'
                     ),
               },
               {
                  path: 'salesman',
                  name: 'invoice-salesman',
                  meta: {
                     permission: 'salesmen',
                     title: 'salesmans',
                  },
                  component: () =>
                     import(
                        '@/views/modules/settings/views/settings/common-settings/salesman/List.vue'
                     ),
               },
               {
                  path: 'client-group',
                  name: 'invoice-client-group',
                  meta: {
                     permission: 'client_groups',
                     title: 'Clients Groups',
                  },
                  component: () =>
                     import(
                        '@/views/modules/settings/views/settings/common-settings/client-group/List.vue'
                     ),
               },
               {
                  path: 'clients',
                  name: 'invoice-clients',
                  meta: {
                     permission: 'clients',
                     title: 'Clients',
                  },
                  component: () =>
                     import(
                        '@/views/modules/settings/views/settings/common-settings/client/List.vue'
                     ),
               },
               {
                  path: 'vendor-group',
                  name: 'invoice-vendor-group',
                  meta: {
                     permission: 'vendor_groups',
                     title: 'Vendor Groups',
                  },
                  component: () =>
                     import(
                        '@/views/modules/settings/views/settings/common-settings/vendor-group/List.vue'
                     ),
               },
               {
                  path: 'vendor',
                  name: 'invoice-vendor',
                  meta: {
                     permission: 'vendors',
                     title: 'Vendors',
                  },
                  component: () =>
                     import(
                        '@/views/modules/settings/views/settings/common-settings/vendor/List.vue'
                     ),
               },
               {
                  path: 'product-categories',
                  name: 'invoice-product-categories',
                  meta: {
                     permission: 'product_categories',
                     title: 'Product Categories',
                  },
                  component: () =>
                     import(
                        '@/views/modules/settings/views/settings/common-settings/product-categories/List.vue'
                     ),
               },
               {
                  path: 'products',
                  name: 'invoicing-management-products',
                  meta: {
                     permission: 'products',
                     title: 'products',
                  },
                  component: () =>
                     import(
                        '@/views/modules/settings/views/settings/common-settings/products/main.vue'
                     ),
                  children: [
                     {
                        path: 'add-product',
                        name: 'invoicing-management-add-product',
                        meta: {
                           permission: 'products',
                           title: 'products',
                           type: 'write',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/common-settings/products/Add.vue'
                           ),
                     },
                     {
                        path: 'update-product/:id',
                        name: 'invoicing-management-update-product',
                        meta: {
                           permission: 'products',
                           title: 'products',
                           type: 'write',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/common-settings/products/Add.vue'
                           ),
                     },
                     {
                        path: 'view-product/:id',
                        name: 'invoicing-management-view-product',
                        meta: {
                           permission: 'products',
                           title: 'products',
                           type: 'write',
                        },
                        component: () =>
                           import(
                              '@/views/modules/settings/views/settings/common-settings/products/Add.vue'
                           ),
                     },
                  ],
               },
               {
                  path: 'import-result',
                  name: 'invoice-import-result',
                  meta: {
                     permission: 'log_exports',
                     title: 'Import Result',
                  },
                  component: () =>
                     import(
                        '@/views/modules/settings/views/settings/common-settings/import-log/Result.vue'
                     ),
               },
               {
                  path: 'import-log',
                  name: 'invoice-import-log',
                  meta: {
                     permission: 'log_exports',
                     title: 'Import Log',
                  },
                  component: () =>
                     import(
                        '@/views/modules/settings/views/settings/common-settings/import-log/Log.vue'
                     ),
               },
               {
                  name: 'invoicing-transferring-log',
                  path: 'transferring-log',
                  meta: {
                     title: 'transferringLog',
                  },
                  component: () => import('@/views/modules/shared/transferring-log/List.vue'),
               },
            ],
         },
         {
            path: '/admin-panel',
            name: 'admin-panel',
            redirect: store.getters.isUserAuthenticated
               ? '/admin-panel/dashboard'
               : '/admin-panel/sign-in',
            meta: {
               title: 'Admin Panel',
            },
            component: () => import('@/views/admin-panel/base.vue'),
            children: [
               ...adminRouters,
               {
                  path: 'dashboard',
                  name: 'admin-panel-dashboard',
                  meta: {
                     title: 'Dashboard',
                  },
                  component: () => import('@/views/admin-panel/views/dashboard/Index.vue'),
               },
               {
                  path: 'dashboard',
                  name: 'admin-panel-dashboard',
                  meta: {
                     title: 'Dashboard',
                  },
                  component: () => import('@/views/admin-panel/views/dashboard/Index.vue'),
               },
               {
                  path: 'admin-profile',
                  name: 'admin-profile',

                  meta: {
                     title: 'My Profile',
                  },
                  component: () => import('@/views/admin-panel/views/adminProfile/edit.vue'),
               },
               {
                  path: 'statistics',
                  name: 'admin-statistics',
                  meta: {
                     title: 'statistics',
                  },
                  component: () => import('@/views/admin-panel/views/statistics/index.vue'),
               },
               {
                  path: 'departments-management',
                  name: 'admin-departments-management',
                  meta: {
                     title: 'Departments',
                     role: 'SUPER_ADMIN',
                  },
                  component: () =>
                     import('@/views/admin-panel/views/departments-management/index.vue'),
               },
               {
                  path: 'update-department/:id',
                  name: 'admin-update-department',
                  meta: {
                     title: 'Departments',
                  },
                  component: () =>
                     import('@/views/admin-panel/views/departments-management/edit.vue'),
               },
               {
                  path: 'coupons',
                  name: 'admin-coupons',
                  meta: {
                     title: 'coupons',
                     role: 'SUPER_ADMIN',
                  },
                  component: () => import('@/views/admin-panel/views/settings/coupons/List.vue'),
               },
               {
                  path: 'banks',
                  name: 'admin-banks',
                  meta: {
                     title: 'banks',
                     role: 'SUPER_ADMIN',
                  },
                  component: () => import('@/views/admin-panel/views/settings/banks/List.vue'),
               },
               {
                  path: 'sections-explanation',
                  name: 'admin-helpers',
                  meta: {
                     title: 'Explanation',
                     role: 'SUPER_ADMIN',
                  },
                  component: () => import('@/views/admin-panel/views/settings/helpers/List.vue'),
               },
               {
                  path: 'send-notifications',
                  name: 'admin-send-notifications',
                  meta: {
                     title: 'Send Notifications',
                     role: 'SUPER_ADMIN',
                  },
                  component: () =>
                     import('@/views/admin-panel/views/settings/notifications/Send.vue'),
               },
               {
                  path: 'privacy-policy',
                  name: 'admin-settings-privacy-policy',
                  meta: {
                     title: 'Privacy Policy',
                     role: 'SUPER_ADMIN',
                  },
                  component: () =>
                     import('@/views/admin-panel/views/settings/privacy-policy/Add.vue'),
               },
               {
                  path: 'blogs',
                  name: 'admin-settings-blogs',
                  meta: {
                     title: 'Blogs',
                     role: 'BLOGGER',
                  },
                  component: () => import('@/views/admin-panel/views/settings/blogs/List.vue'),
               },
               {
                  path: 'configs',
                  name: 'admin-configs',
                  meta: {
                     title: 'Configs',
                     role: 'SUPER_ADMIN',
                  },
                  component: () => import('@/views/admin-panel/views/settings/configs/List.vue'),
               },
               {
                  path: 'messages',
                  name: 'admin-messages',
                  meta: {
                     title: 'visitor messages',
                  },
                  component: () => import('@/views/admin-panel/views/messages/List.vue'),
               },
               {
                  path: 'subscriptions',
                  name: 'admin-subscriptions',
                  meta: {
                     title: 'Payments',
                     role: `${AdminRoles.SUPER_ADMIN}|${AdminRoles.ACCOUNTANT}|${AdminRoles.CRM}`,
                  },
                  component: () => import('@/views/admin-panel/views/subscription/List.vue'),
               },
               {
                  path: 'subscription-details/:id',
                  name: 'admin-subscription-details',
                  meta: {
                     title: 'Payments',
                     role: `${AdminRoles.SUPER_ADMIN}|${AdminRoles.ACCOUNTANT}|${AdminRoles.CRM}`,
                  },
                  component: () => import('@/views/admin-panel/views/subscription/ViewDetails.vue'),
               },
               {
                  path: 'companies',
                  name: 'admin-companies',
                  meta: {
                     title: 'Registered Companies',
                     role: `${AdminRoles.SUPER_ADMIN}|${AdminRoles.ACCOUNTANT}|${AdminRoles.CRM}`,
                  },
                  component: () => import('@/views/admin-panel/views/companies/List.vue'),
               },
               {
                  path: 'companies/add',
                  name: 'admin-add-companies',
                  meta: {
                     title: 'companies',
                     role: `${AdminRoles.SUPER_ADMIN}|${AdminRoles.ACCOUNTANT}|${AdminRoles.CRM}`,
                  },
                  component: () => import('@/views/admin-panel/views/companies/Add.vue'),
               },
               {
                  path: 'companies/edit/:id',
                  name: 'admin-edit-companies',
                  meta: {
                     title: 'companies',
                     role: `${AdminRoles.SUPER_ADMIN}|${AdminRoles.ACCOUNTANT}|${AdminRoles.CRM}`,
                  },
                  component: () => import('@/views/admin-panel/views/companies/Add.vue'),
               },
               {
                  path: 'companies/view/:id',
                  name: 'admin-view-company',
                  meta: {
                     title: 'companies',
                     role: `${AdminRoles.SUPER_ADMIN}|${AdminRoles.ACCOUNTANT}|${AdminRoles.CRM}`,
                  },
                  component: () => import('@/views/admin-panel/views/companies/View.vue'),
               },
               //begin::subscribed-customers
               {
                  path: 'subscribed-customers',
                  name: 'admin-subscribed-customers',
                  meta: {
                     title: 'Subscribed Customers',
                     role: `${AdminRoles.SUPER_ADMIN}|${AdminRoles.ACCOUNTANT}|${AdminRoles.CRM}`,
                  },
                  component: () =>
                     import('@/views/admin-panel/views/subscribed-customers/List.vue'),
               },
               {
                  path: 'subscribed-customers/edit/:id',
                  name: 'admin-edit-subscribed-customers',
                  meta: {
                     title: 'Subscribed Customers',
                     role: `${AdminRoles.SUPER_ADMIN}|${AdminRoles.ACCOUNTANT}|${AdminRoles.CRM}`,
                  },
                  component: () => import('@/views/admin-panel/views/companies/Add.vue'),
               },
               {
                  path: 'subscribed-customers/view/:id',
                  name: 'admin-view-company',
                  meta: {
                     title: 'Subscribed Customers',
                     role: `${AdminRoles.SUPER_ADMIN}|${AdminRoles.ACCOUNTANT}|${AdminRoles.CRM}`,
                  },
                  component: () => import('@/views/admin-panel/views/companies/View.vue'),
               },
               //end::subscribed-customers
               {
                  path: 'print-models',
                  name: 'print-models',
                  meta: {
                     title: 'print models',
                  },
                  component: () => import('@/views/admin-panel/views/print-models/List.vue'),
               },
               {
                  path: 'users',
                  name: 'admin-users',
                  meta: {
                     title: 'admins',
                     role: 'SUPER_ADMIN',
                  },
                  component: () => import('@/views/admin-panel/views/users/List.vue'),
               },
               {
                  path: 'visitors',
                  name: 'admin-visitors',

                  meta: {
                     title: 'Visitors',
                     role: `${AdminRoles.SUPER_ADMIN}|${AdminRoles.ACCOUNTANT}|${AdminRoles.CRM}`,
                  },
                  component: () => import('@/views/admin-panel/views/visitors/List.vue'),
               },
               {
                  path: 'gallery-visitors',
                  name: 'admin-gallery-visitors',

                  meta: {
                     title: 'Gallery Visitors',
                     role: `${AdminRoles.SUPER_ADMIN}|${AdminRoles.CRM}`,
                  },
                  component: () => import('@/views/admin-panel/views/gallery-visitors/List.vue'),
               },
               {
                  path: 'customers',
                  name: 'admin-customers',

                  meta: {
                     title: 'Managers',
                     role: `${AdminRoles.SUPER_ADMIN}|${AdminRoles.CRM}`,
                  },
                  component: () => import('@/views/admin-panel/views/customers/List.vue'),
               },
               {
                  path: 'customers/show/:id',
                  name: 'show-admin-customers',
                  meta: {
                     title: 'Managers',
                     role: `${AdminRoles.SUPER_ADMIN}|${AdminRoles.CRM}`,
                  },
                  component: () => import('@/views/admin-panel/views/customers/Show.vue'),
               },
            ],
         },
         {
            path: 'restaurant-management',
            name: 'restaurant-management',
            redirect: '/restaurant-management/restaurants-dashboard',
            component: () => import('@/views/modules/restaurants/base.vue'),
            children: [...restaurantsRouters],
         },
         {
            path: 'pos-management',
            name: 'pos-management',
            redirect: '/pos-management/pos-dashboard',
            component: () => import('@/views/modules/pos/base.vue'),
            children: [...posRouters],
         },
         {
            path: 'electronic-approval-management',
            name: 'electronic-approval-management',
            redirect: '/electronic-approval-management/electronic-approval-dashboard',
            component: () => import('@/views/modules/electronic-approvals/base.vue'),
            children: [...electronicApprovalsRoutes],
         },
         {
            path: 'assets-management',
            name: 'assets-management',
            redirect: '/assets-management/assets-dashboard',
            component: () => import('@/views/modules/assets/base.vue'),
            children: [...assetsRoutes],
         },
      ],
   },
   {
      path: '/',
      component: () => import('@/components/page-layouts/Auth.vue'),
      children: [
         {
            path: '/sign-in',
            name: 'sign-in',
            meta: {
               title: 'Sign In',
            },
            component: () => import('@/views/crafted/authentication/basic-flow/SignIn.vue'),
         },
         {
            path: '/sign-up',
            name: 'sign-up',
            meta: {
               title: 'Create an Account',
            },
            component: () => import('@/views/crafted/authentication/basic-flow/SignUp.vue'),
         },
         {
            path: '/try-doso',
            name: 'try-doso',
            meta: {
               title: 'Explore Doso',
            },
            component: () => import('@/views/crafted/authentication/basic-flow/TryDoso.vue'),
         },
         {
            path: '/otp',
            name: 'otp',
            meta: {
               title: 'Verification Code',
            },
            component: () => import('@/views/crafted/authentication/basic-flow/otp.vue'),
         },
         {
            path: '/user-email-verification',
            name: 'user-email-verification',
            meta: {
               title: 'Verify an Account',
            },
            component: () => import('@/views/crafted/authentication/basic-flow/VerifyEmail.vue'),
         },
         {
            path: '/multi-companies-select',
            name: 'multi-companies-select',
            meta: {
               title: 'company list',
            },
            component: () =>
               import('@/views/crafted/authentication/basic-flow/MultiCompanySelect.vue'),
         },
         {
            path: '/multi-pos-select',
            name: 'multi-pos-select',
            meta: {
               title: 'Pos List',
            },
            component: () => import('@/views/modules/casher/MultiPosSelect.vue'),
         },
         {
            path: '/user-email-verification',
            name: 'user-email-verification',
            meta: {
               title: 'Verify an Account',
            },
            component: () => import('@/views/crafted/authentication/basic-flow/VerifyEmail.vue'),
         },
         {
            path: '/user-invitation',
            name: 'user-invitation',
            component: () => import('@/views/crafted/authentication/basic-flow/UserInvitation.vue'),
         },
         {
            path: '/user-complete-profile',
            name: 'user-complete-profile',
            component: () =>
               import('@/views/crafted/authentication/basic-flow/UserCompleteProfile.vue'),
         },
         {
            path: '/create-new-company',
            name: 'create-new-company',
            meta: {
               title: 'create new company',
            },
            component: () =>
               import('@/views/crafted/authentication/basic-flow/CreateNewCompany.vue'),
         },
         {
            path: '/create-company-without-login',
            name: 'create-company-without-login',
            meta: {
               title: 'create new company',
            },
            component: () =>
               import('@/views/crafted/authentication/basic-flow/CreateCompanyWithoutLogin.vue'),
         },
         {
            path: '/forgot-password',
            name: 'forgot-password',
            meta: {
               title: 'passwordReset',
            },
            component: () => import('@/views/crafted/authentication/basic-flow/ForgotPassword.vue'),
         },
         {
            path: '/reset-password',
            name: 'reset-password',
            meta: {
               title: 'passwordReset',
            },
            component: () => import('@/views/crafted/authentication/basic-flow/ResetPassword.vue'),
         },
         {
            path: '/privacy-policy',
            name: 'admin-privacy-policy',
            meta: {
               title: 'Privacy Policy',
            },
            component: () => import('@/views/crafted/authentication/PrivacyPolicy.vue'),
         },
         {
            path: '/about-us',
            name: 'admin-about-us',
            meta: {
               title: 'about us',
            },
            component: () => import('@/views/crafted/authentication/AboutUs.vue'),
         },
         {
            path: '/contact-us',
            name: 'admin-contact-us',
            meta: {
               title: 'contact us',
            },
            component: () => import('@/views/crafted/authentication/ContactUs.vue'),
         },
      ],
   },
   {
      path: '/',
      component: () => import('@/components/page-layouts/AdminAuth.vue'),
      children: [
         {
            path: '/admin-panel/sign-in',
            name: 'admin-sign-in',
            meta: {
               title: 'Sign In',
            },
            component: () => import('@/views/admin-panel/views/authentication/SignIn.vue'),
         },
         {
            path: 'admin-panel/forgot-password',
            name: 'admin-forgot-password',

            meta: {
               title: 'Sign In',
            },
            component: () =>
               import('@/views/crafted/authentication/basic-flow/AdminForgotPassword.vue'),
         },
         {
            path: '/admin-panel/reset-password',
            name: 'admin-reset-password',
            meta: {
               title: 'passwordReset',
            },
            component: () =>
               import('@/views/crafted/authentication/basic-flow/AdminResetPassword.vue'),
         },
         {
            path: '/admin-panel/privacy-policy',
            name: 'privacy-policy',
            meta: {
               title: 'Privacy Policy',
            },
            component: () => import('@/views/crafted/authentication/PrivacyPolicy.vue'),
         },
         {
            path: '/admin-panel/about-us',
            name: 'about-us',
            meta: {
               title: 'about us',
            },
            component: () => import('@/views/crafted/authentication/AboutUs.vue'),
         },
         {
            path: '/admin-panel/contact-us',
            name: 'contact-us',
            meta: {
               title: 'contact us',
            },
            component: () => import('@/views/crafted/authentication/ContactUs.vue'),
         },
      ],
   },
   {
      path: '/',
      component: () => import('@//components/page-layouts/Seamless.vue'),
      children: [
         {
            path: '/Seamless',
            name: 'seamless',
            meta: {
               title: 'Visitors',
            },
            component: () => import('@/views/crafted/authentication/basic-flow/VisitorSignUp.vue'),
         },
         {
            path: '/visitor-sign-up',
            name: 'visitor-sign-up',
            redirect: '/Seamless',
         },
      ],
   },
   {
      path: '/multi-step-sign-up',
      name: 'multi-step-sign-up',
      component: () => import('@/views/crafted/authentication/MultiStepSignUp.vue'),
   },
   {
      path: '/sitemap.xml',
      component: () => import('@/views/Sitemap.vue'),
   },
   {
      path: '/robots.txt',
      component: () => import('@/views/Robots.vue'),
   },
   {
      path: '/404',
      name: '404',
      component: () => import('@/views/crafted/authentication/Error404.vue'),
   },
   {
      path: '/500',
      name: '500',
      component: () => import('@/views/crafted/authentication/Error500.vue'),
   },
   {
      path: '/:pathMatch(.*)*',
      redirect: '/404',
   },
];

const router = createRouter({
   history: createWebHashHistory(),
   routes,
   scrollBehavior(to, from, savedPosition) {
      if (to.hash) {
         return {
            el: to.hash,
            behavior: 'smooth',
         };
      }
   },
});

function redirectToCashier() {
   const modules = store.getters.currentCompany?.modules;
   const currentUserModules = store.getters.currentUser?.modules || [];
   const myModules = modules?.filter((el) => currentUserModules?.includes(el.id)) || [];

   if (myModules.length > 1 || !AuthService.userHasModule(MODULES_IDS.POS_MANAGEMENT)) return false;
   const permissions = [
      'read_pos_setting',
      'read_pos_delivery_zones',
      'read_pos_sections',
      'read_pos_branches',
      'read_pos_point_sales',
      'read_pos_offers',
      'read_pos_fund_movements',
      'write_pos_coupons',
      'read_pos_dashboard',
      'write_pos_expenses',
      'read_pos_reports',
   ];

   return permissions.every((p) => !AuthService.hasPermission(p));
}

let isFirstLoad = true;

router.beforeEach(async (to, from, next) => {
   store.commit(Mutations.RESET_LAYOUT_CONFIG);
   if (from.path === '/' && JwtService.getUser()) {
      store.dispatch(Actions.SET_USER, JwtService.getUser());
   }

   if (!isFirstLoad) {
      await store.dispatch(Actions.REQUEST_VERIFY_AUTH);

      if (
         redirectToCashier() &&
         store.getters.isUserAuthenticated &&
         !JwtService.isAdminDomain() &&
         CookieService.getCookie('X-Tenant-Id') &&
         !to.path.includes('multi-companies-select')
      ) {
         store.dispatch(PosActions.GET_DATA_LIST).then((resp) => {
            const posList = resp?.result?.item;
            if (!to.path.includes('casher') && !to.path.includes('multi-pos-select')) {
               if (posList.length === 1) {
                  store.dispatch(PosActions.SET_CURRENT_POS_ID, posList?.[0]?.id);
                  next('/casher');
                  UrlService.redirectWithoutSubdomain('/#/casher');
                  window.location.reload();
               } else if (posList.length > 0) {
                  next('/multi-pos-select');
                  UrlService.redirectWithSubdomain('/#/multi-pos-select');
                  window.location.reload();
               }
            }
         });
      }
   } else {
      isFirstLoad = false;
   }
   const permissionMeta = to.matched
      .slice()
      .reverse()
      .find((r) => r.meta && r.meta.permission);

   const roleMeta = to.matched
      .slice()
      .reverse()
      .find((r) => r.meta && r.meta.role);

   const operationMeta = to.matched
      .slice()
      .reverse()
      .find((r) => r.meta && r.meta.operation);

   if (permissionMeta || roleMeta) {
      let validPermission = true;
      let validRole = true;

      if (permissionMeta) {
         const type = permissionMeta?.meta.type ? permissionMeta?.meta.type : 'read';
         validPermission = AuthService.hasPermission(type + '_' + permissionMeta?.meta.permission);
      }
      if (roleMeta) {
         validRole = AuthService.hasRole(roleMeta?.meta?.role);
      }
      let isShowPage = validPermission && validRole;

      if (operationMeta && operationMeta?.meta?.operation == 'or')
         isShowPage = validPermission || validRole;

      if (isShowPage) {
         next();
      } else {
         if (
            store.getters.isUserAuthenticated &&
            CookieService.getCookie('userType') == USER_TYPE.ADMIN
         ) {
            UrlService.redirectWithoutSubdomain('/#/admin-panel');
         } else if (!store.getters.isUserAuthenticated) {
            if (JwtService.isAdminDomain()) next('/admin-panel/sign-in');
         }
         next('/404');
      }
   }
   if (!to.hash) {
      setTimeout(() => {
         window.scrollTo(0, 0);
      }, 100);
   }

   next();
});

const DEFAULT_TITLE = 'Doso';

router.afterEach((to, from) => {
   nextTick(() => {
      let title: string = i18n.global.t(DEFAULT_TITLE) as string;
      if (to.meta.title)
         title = i18n.global.t(DEFAULT_TITLE) + ' - ' + i18n.global.t(to.meta.title);
      document.title = title;
   });
   const description = to.meta && to.meta.description;
   if (description) {
      const descriptionString = description as string; // type assertion

      document
         .querySelector('meta[name="description"]')
         .setAttribute('content', i18n.global.t(descriptionString));
   }
});

const redirectRoutes = [
   {
      path: '/dosoerp',
      redirect: { path: 'https://www.dosoerp.com/' },
      status: 301,
   },
   {
      path: '/www.dosoerp',
      redirect: { path: 'https://www.dosoerp.com/' },
      status: 301,
   },
   {
      path: '/www.dosoerp.com',
      redirect: { path: 'https://www.dosoerp.com/' },
      status: 301,
   },
   {
      path: '/dosoerp.com',
      redirect: { path: 'https://www.dosoerp.com/' },
      status: 301,
   },
   {
      path: '/http://www.dosoerp.com/',
      redirect: { path: 'https://www.dosoerp.com/' },
      status: 301,
   },
];

redirectRoutes.forEach((route) => router.addRoute(route));

export default router;
