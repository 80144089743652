import { RouteRecordRaw } from 'vue-router';

const reportsRouter: Array<RouteRecordRaw> = [
   {
      path: 'overall-detailed-reports',
      name: 'customs-overall-detailed-reports',
      meta: {
         title: 'Sales Transactions',
         permission: 'customs_reports',
      },
      component: () =>
         import('@/views/modules/customs/views/reports/sales/overall-detailed-reports/base.vue'),
   },
   {
      path: 'clients-transaction-report',
      name: 'customs-clients-transaction-report',
      meta: {
         title: 'Client/Products Transaction',
         permission: 'customs_reports',
      },
      component: () =>
         import('@/views/modules/customs/views/reports/sales/client-transactions-reports/base.vue'),
   },
   {
      path: 'overall-model-report',
      name: 'customs-overall-model-report',
      meta: {
         title: 'Model',
         permission: 'customs_reports',
      },
      component: () =>
         import('@/views/modules/customs/views/reports/sales/overall-model-reports/base.vue'),
   },
   {
      path: 'product-sales-report',
      name: 'customs-product-sales-report',
      meta: {
         title: 'Product Sales',
         permission: 'customs_reports',
      },
      component: () =>
         import('@/views/modules/customs/views/reports/sales/product-sales-report/base.vue'),
   },
   {
      path: 'sales-business-transaction-report',
      name: 'customs-sales-business-transaction-report',
      meta: {
         title: 'Sales with tax',
         permission: 'customs_reports',
      },
      component: () =>
         import(
            '@/views/modules/customs/views/reports/sales/sales-business-movement-report/base.vue'
         ),
   },
   {
      path: 'client-account-statement-report',
      name: 'customs-client-account-statement-report',
      meta: {
         title: 'Clients Account Statement',
         permission: 'customs_reports',
      },
      component: () =>
         import(
            '@/views/modules/customs/views/reports/sales/client-account-statement-report/base.vue'
         ),
   },
   {
      path: 'clients-groups-details-transactions-report',
      name: 'customs-clients-groups-details-transactions-report',
      meta: {
         title: 'Clients Groups Details Transactions',
         permission: 'customs_reports',
      },
      component: () =>
         import(
            '@/views/modules/customs/views/reports/sales/clients-groups-details-transactions-report/base.vue'
         ),
   },
   {
      path: 'customs-broker-sales-transactions-report',
      name: 'transport-customs-broker-sales-transactions-report',
      meta: {
         title: 'Customs Broker Sales Transactions',
         permission: 'customs_reports',
      },
      component: () =>
         import(
            '@/views/modules/customs/views/reports/sales/customs-broker-sales-transactions-reports/base.vue'
         ),
   },

   {
      path: 'client-deals-report',
      name: 'client-deals-report',
      meta: {
         title: 'Client Deals',
         permission: 'customs_reports',
      },
      component: () => import('@/views/modules/customs/views/reports/client-deals/base.vue'),
   },
   {
      path: 'broker-deals-report',
      name: 'broker-deals-report',
      meta: {
         title: 'Broker Deals',
         permission: 'customs_reports',
      },
      component: () => import('@/views/modules/customs/views/reports/broker-deals/base.vue'),
   },
   {
      path: 'expiry-customs-brokers-report',
      name: 'expiry-customs-brokers-report',
      meta: {
         title: 'Broker Deals',
         permission: 'customs_reports',
      },
      component: () =>
         import('@/views/modules/customs/views/reports/expiry-customs-brokers/base.vue'),
   },
   {
      path: 'deals-in-a-duration-report',
      name: 'deals-in-a-duration-report',
      meta: {
         title: 'Deals In A Duration',
         permission: 'customs_reports',
      },
      component: () => import('@/views/modules/customs/views/reports/deals-in-a-duration/base.vue'),
   },
   {
      path: 'expenses-report',
      name: 'expenses-report',
      meta: {
         title: 'Expenses Report',
         permission: 'customs_reports',
      },
      component: () => import('@/views/modules/customs/views/reports/expenses-report/base.vue'),
   },
   {
      path: 'incomes-report',
      name: 'incomes-report',
      meta: {
         title: 'Incomes',
         permission: 'customs_reports',
      },
      component: () => import('@/views/modules/customs/views/reports/incomes-report/base.vue'),
   },
   {
      path: 'incomes-expenses-report',
      name: 'incomes-expenses-report',
      meta: {
         title: 'Incomes and Expenses',
         permission: 'customs_reports',
      },
      component: () =>
         import('@/views/modules/customs/views/reports/incomes-expenses-report/base.vue'),
   },
   {
      path: 'reopened-deals',
      name: 'reopened-deals',
      meta: {
         title: 'Reopened Deals',
         permission: 'customs_reports',
      },
      component: () => import('@/views/modules/customs/views/reports/reopened-deals/base.vue'),
   },
   {
      path: 'tax-declaration-report',
      name: 'customs-tax-declaration-report',
      meta: {
         title: 'Tax Declaration',
         permission: 'customs_reports',
      },
      component: () =>
         import('@/views/modules/customs/views/reports/common/tax-declaration-report/Index.vue'),
   },
];

export default reportsRouter;
