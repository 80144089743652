import { ModulesNames } from '@/core/mixins/global/financialYear';
import { callListeners } from '@/core/plugins/DemoCompany';
import AlertService from '@/core/services/AlertService';
import AuthService from '@/core/services/AuthService';
import store from '@/store';
import { Actions } from '@/store/enums/StoreEnums';
import { MODULES_IDS } from '@/views/modules/shared/constants/ModulesNames';
import axios from 'axios';
import { debounce } from 'lodash';
import moment from 'moment';

export const CompanyActions = {
   VALIDATE_EXPIRY_DATE: 'CompanyModule/validateExpiryDate',
   GET_COMPANY_SETTING: 'CompanyModule/getCompanySetting',
};

const debouncedAlert = debounce(
   (message) => {
      AlertService.errorText(message);
   },
   1000,
   { leading: false, trailing: true },
);

const CompanyModule = {
   namespaced: true,
   loading: false,
   crudLoading: false,
   state: {
      // companySetting : null
      companySetting: {
         translations: { ar: { name: 'd' }, en: { name: '' } },
         contact: {
            address: {
               postal_code: '',
               extra_number: '',
               unit_number: '',
               street: '',
               translations: { ar: { address: '' }, en: { address: '' } },
            },
            phones: [{ number: '' }, { number: '' }, { number: '' }],
            emails: { email: '' },
         },
      },
      helper: {},
      extra_data: {},
   },
   mutations: {
      SET_LOADING(state, payload) {
         state.loading = payload;
      },
      SET_CRUD_LOADING(state, payload) {
         state.crudLoading = payload;
      },
      SET_HELPER(state, payload) {
         state.helper = payload.data;
      },
      SET_EXTRA_DATA(state, payload) {
         state.extra_data = payload.data;
      },
      SET_COMPANY_SETTING(state, payload) {
         let data = payload.data;
         callListeners(Boolean(data.is_demo));

         if (data.translations) {
            let arr = {};

            data.translations.map((lang) => {
               arr[lang.locale] = {
                  name: lang?.name,
                  local_currency_parts: lang?.local_currency_parts,
               };
            });
            data['translations'] = arr;
         }

         data['contact']['phone'] = data['contact']['phones'];
         data['contact']['email'] = data['contact']['emails'];

         if (data.contact.address?.translations) {
            let array = {};
            data.contact.address.translations.map((lang) => {
               array[lang.locale] = lang;
            });
            Object.keys(array).map((item, index) => {
               data['contact']['address'][array[item]['locale']] = array[item];
            });
         }
         state.companySetting = data;
      },
   },
   actions: {
      getCompanySetting({ commit }) {
         commit('SET_LOADING', true);
         return new Promise((resolve, reject) => {
            axios
               .get(`company/setting/get-setting`)
               .then((res) => {
                  commit('SET_COMPANY_SETTING', {
                     data: { ...res.data.result.company, ...(res.data.result.configs || {}) },
                  });
                  commit('SET_HELPER', { data: res.data.result?.helper });
                  commit('SET_EXTRA_DATA', { data: res.data.result?.extra_data });
                  commit('SET_LOADING', false);
                  resolve(res);
               })
               .catch((error) => {
                  console.log(error);
                  commit('SET_LOADING', false);
                  reject(error);
               });
         });
      },
      deleteCompany({ commit }) {
         commit('SET_LOADING', true);
         return new Promise((resolve, reject) => {
            axios
               .get(`company/setting/delete-company`)
               .then((res) => {
                  resolve(res);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      updateCompanySetting({ commit }, data) {
         commit('SET_CRUD_LOADING', true);
         return new Promise((resolve, reject) => {
            axios
               .post(`company/setting/update-setting`, data)
               .then((res) => {
                  commit('SET_CRUD_LOADING', false);
                  resolve(res);
               })
               .catch((error) => {
                  console.log(error);
                  commit('SET_CRUD_LOADING', false);
                  reject(error);
               });
         });
      },
      validateExpiryDate({ state }, { moduleName, date }) {
         function getModuleSettings(moduleName) {
            const companySetting = state.companySetting;

            if ([ModulesNames.INVOICE, ModulesNames.WAREHOUSE].includes(moduleName)) {
               if (AuthService.hasModule(MODULES_IDS.WAREHOUSE_MANAGEMENT)) {
                  return companySetting?.warehouse_settings;
               } else {
                  return companySetting?.invoice_settings;
               }
            }

            return companySetting?.[`${moduleName}_settings`];
         }

         if (!date) return;

         const settings = getModuleSettings(moduleName);

         if (settings?.expiry_date_alert && settings?.expiry_date_alert_days) {
            const days = settings.expiry_date_alert_days;
            const diff = moment(date).diff(moment(), 'days');

            const alert = diff < days;

            if (alert) {
               debouncedAlert(diff < 0 ? 'Expired Product' : 'Product soon to be expired');
            }
         }
      },
   },
   getters: {
      getHrSetting(state) {
         return state.companySetting?.hr_settings;
      },
      getCompanySetting(state) {
         return state.companySetting;
      },
   },
};
export default CompanyModule;
