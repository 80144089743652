const WarehouseMenuConfig = [
   {
      heading: 'settings',
      route: '/settings',
      module_id: 2,
      pages: [
         {
            heading: 'Warehouse settings',
            route: '/warehouse-management/setting',
            permission: 'warehouse_setting',
         },
         {
            heading: 'Warehouses',
            route: '/warehouse-management/warehouse',
            permission: 'warehouses',
         },
         {
            heading: 'Unit Of Measure',
            route: '/warehouse-management/unit',
            permission: 'units',
         },
         {
            heading: 'Taxes',
            route: '/warehouse-management/taxes',
            permission: 'taxes',
         },
         {
            heading: 'product categories',
            route: '/warehouse-management/product-categories',
         },
         {
            heading: 'products',
            route: '/warehouse-management/products',
            permission: 'products',
         },
         {
            heading: 'Commission Policy',
            route: '/warehouse-management/commission-policy',
            permission: 'commission_policies',
         },
         {
            heading: 'salesmans',
            route: '/warehouse-management/salesman',
            permission: 'salesmen',
         },
         // {
         //   heading: "Salesman Commissions",
         //   route: "/warehouse-management/salesman",
         //   permission: "salesmen"
         // },
         {
            heading: 'Clients Groups',
            route: '/warehouse-management/client-group',
            permission: 'client_groups',
         },
         {
            heading: 'Vendor Groups',
            route: '/warehouse-management/vendor-group',
            permission: 'vendor_groups',
         },
         {
            heading: 'Clients',
            route: '/warehouse-management/clients',
            permission: 'clients',
         },
         {
            heading: 'Vendors',
            route: '/warehouse-management/vendor',
            permission: 'vendors',
         },
         {
            heading: 'Workflow Forms',
            module_id: 2,
            route: '/warehouse-management/model-settings',
         },
         {
            heading: 'Import Result',
            route: '/warehouse-management/import-result',
            permission: 'log_exports',
         },
         {
            heading: 'Import Log',
            route: '/warehouse-management/import-log',
            permission: 'log_exports',
         },
      ],
   },
   {
      heading: 'Daily Transaction', //Sales Models
      route: '#',
      module_id: 2,
      name: 'daily_movements',
      pages: [
         {
            sectionTitle: 'Purchase',
            route: '/purchase',
            name: 'purchase',
            permission: 'purchase_daily_movements'
         },
         {
            sectionTitle: 'Sales',
            route: '/Sales',
            name: 'sales',
            permission: 'sales_daily_movements'
         },
         {
            sectionTitle: 'Inventory1',
            route: 'inventory',
            sub: [
               {
                  heading: 'Stock Receipt Voucher',
                  route: '/warehouse-management/inventory/add-notice',
                  permission: "add_notices"
               },
               {
                  heading: 'Stock Issue Voucher',
                  route: '/warehouse-management/inventory/dismissal-notice',
                  permission: "dismissal_notices"
               },
               {
                  heading: 'Return Receipt Voucher',
                  route: '/warehouse-management/inventory/return-add-notice',
                  permission: "return_add_notices"
               },
               {
                  heading: 'Return Issue Voucher',
                  route: '/warehouse-management/inventory/return-dismissal-notice',
                  permission: "return_dismissal_notices"
               },
               {
                  heading: 'Stock Transfer Voucher',
                  route: '/warehouse-management/inventory/transfer-notice',
                  permission: "transfer_notices"
               },
               {
                  heading: 'Stock adjustments',
                  route: '/warehouse-management/stocktaking',
                  permission: "stocktaking"
               },
            ],
         },
      ],
   },
   {
      heading: 'Reports',
      route: '/reports',
      pages: [
         {
            heading: 'Tax Declaration',
            route: '/warehouse-management/reports/tax-declaration-report',
         },
         {
            heading: 'Model',
            route: '/warehouse-management/reports/overall-model-report',
         },
         {
            sectionTitle: 'Purchase',
            sub: [
               {
                  heading: 'Purchase Models',
                  route: '/warehouse-management/reports/purchase-transaction-reports',
               },
               {
                  heading: 'Product Purchase',
                  route: '/warehouse-management/reports/product-purchase-report',
               },
               {
                  heading: 'Purchases Transaction with tax',
                  route: '/warehouse-management/reports/purchase-business-transaction-report',
               },
               {
                  heading: 'Salesman Purchase Transactions',
                  route: '/warehouse-management/reports/salesman-purchase-transactions-report',
               },
               {
                  heading: 'Vendor Account Statement',
                  route: '/warehouse-management/reports/vendor-account-statement-report',
               },
               {
                  heading: 'Vendors Groups Details Transactions',
                  route: '/warehouse-management/reports/vendors-groups-details-transactions-report',
               },
               {
                  heading: 'Vendors Transaction',
                  route: '/warehouse-management/reports/vendors-transaction-report',
               },
            ],
         },
         {
            sectionTitle: 'Sales',
            sub: [
               {
                  heading: 'Sales Transaction',
                  route: '/warehouse-management/reports/overall-detailed-reports',
               },
               {
                  heading: 'Product Sales',
                  route: '/warehouse-management/reports/product-sales-report',
               },
               {
                  heading: 'Best Selling Products',
                  route: '/warehouse-management/reports/best-selling-products-report',
               },
               {
                  heading: 'Sales with tax',
                  route: '/warehouse-management/reports/sales-business-transaction-report',
               },
               {
                  heading: 'Sales of representatives',
                  route: '/warehouse-management/reports/salesman-sales-transactions-report',
               },
               {
                  heading: 'Sales Performance Evaluation',
                  route: '/warehouse-management/reports/sales-performance-evaluation-report',
               },
               {
                  heading: 'Clients Account Statement',
                  route: '/warehouse-management/reports/client-account-statement-report',
               },
               {
                  heading: 'Customer Debt Aging',
                  route: '/warehouse-management/reports/customer-debt-aging-report',
               },
               {
                  heading: 'Customers info',
                  route: '/warehouse-management/reports/customers-info',
               },
               {
                  heading: 'Best Selling Customers',
                  route: '/warehouse-management/reports/best-selling-customers-report',
               },
               {
                  heading: 'Clients Groups Sales Transactions',
                  route: '/warehouse-management/reports/clients-groups-details-transactions-report',
               },
               {
                  heading: 'Customer Trial Balance',
                  route: '/warehouse-management/reports/customer-trial-balance-report',
               },
               {
                  heading: 'Client/Products Transaction',
                  route: '/warehouse-management/reports/clients-transaction-report',
               },
               {
                  heading: 'Branch Sales Transaction',
                  route: '/warehouse-management/reports/branch-sales-report',
               },
               {
                  heading: 'Representatives Commissions',
                  route: '/warehouse-management/reports/salesman-commissions',
               },
            ],
         },
         {
            sectionTitle: 'Warehouse',
            sub: [
               {
                  heading: 'Product Data',
                  route: '/warehouse-management/reports/product-data-reports',
               },
               {
                  heading: 'Product Stock',
                  route: '/warehouse-management/reports/product-stock-reports',
               },
               {
                  heading: 'Product Profits',
                  route: '/warehouse-management/reports/product-profits-reports',
               },
               {
                  heading: 'Product Transaction',
                  route: '/warehouse-management/reports/product-transaction-reports',
               },
            ],
         },
         {
            sectionTitle: 'Stock adjustment',
            sub: [
               {
                  heading: 'Adjustment',
                  route: '/warehouse-management/reports/stocktaking-details-report',
               },
               {
                  heading: 'Receipt Settlement',
                  route: '/warehouse-management/reports/addition-settlement-report',
               },
               {
                  heading: 'expense settlement report',
                  route: '/warehouse-management/reports/expense-settlement-report',
               },
            ],
         },
      ],
   },
];
export default WarehouseMenuConfig;
