const CargoMenuConfig = [
      {
         heading: 'settings',
         route: '/settings',
         module_id: 35,
         pages: [
            {
               heading: 'Transportation Settings',
               route: '/transport-management/settings-transportation',
               permission: 'transport_setting',
            },
            {
               heading: 'Unit Of Measure',
               route: '/transport-management/unit',
               permission: 'units',
            },
            {
               heading: 'Taxes',
               route: '/transport-management/taxes',
               permission: 'taxes',
            },
            {
               heading: 'products',
               route: '/transport-management/services',
               permission: 'transport_products',
            },
            {
               heading: 'salesmans',
               route: '/transport-management/salesman',
               permission: 'salesmen',
            },
            {
               heading: 'Clients Groups',
               route: '/transport-management/client-group',
               permission: 'client_groups',
            },
            {
               heading: 'Transporter Groups',
               route: '/transport-management/transporter-group',
               permission: 'vendor_groups',
            },
            {
               heading: 'Clients',
               route: '/transport-management/clients',
               permission: 'clients',
            },
            {
               heading: 'transporters',
               route: '/transport-management/transporter',
               // permission: "transports",
            },
            {
               heading: 'Cars',
               route: '/transport-management/transport-cars',
               permission: 'transport_cars',
            },
            {
               heading: 'Trucks',
               route: '/transport-management/transport-trucks',
               permission: 'transport_trucks',
            },
            {
               heading: 'Trailers',
               route: '/transport-management/transport-trailers',
               permission: 'transport_trailers',
            },
            {
               heading: 'Directions',
               route: '/transport-management/transport-directions',
               permission: 'transport_directions',
            },
            {
               heading: 'Drivers',
               route: '/transport-management/transport-drivers',
               permission: 'transport_drivers',
            },
            {
               heading: 'Cars Expenses',
               route: '/transport-management/transport-car-expenses',
               permission: 'transport_car_expenses',
            },
            {
               heading: 'Workflow Forms',
               module_id: 35,
               route: '/transport-management/model-settings',
               permission: 'transport_invoice_models',
            },
         ],
      },
      {
         heading: 'Daily Transactions',
         route: '#',
         name: 'daily_movements',
         module_id: 35,
         pages: [
            {
               heading: 'Transport Transactions',
               route: '/transport-management/transport-transactions/list',
               module_id: 35,
               permission: 'transport_transport_movements',
            },
            {
               heading: 'Downloads transactions',
               route: '/transport-management/daily-transactions/downloads-transactions',
               module_id: 35,
               permission: 'transport_download_movements',
            },
            {
               heading: 'Return Transactions',
               route: '/transport-management/return-transactions/list',
               permission: 'transport_return_movements',
            },
            {
               heading: 'Transport Transaction Expenses',
               route: '/transport-management/transport-transaction-expenses/list',
               permission: 'transport_movement_expenses',
            },
            {
               sectionTitle: 'Purchase',
               route: '/purchase',
               permission: 'transport_sales_invoices',
               name: 'purchase',
            },
            {
               sectionTitle: 'Sales',
               route: '/Sales',
               permission: 'transport_purchase_invoices',
               name: 'sales',
            },
         ],
      },
      {
         heading: 'Reports',
         route: '/reports',
         pages: [
            {
               heading: 'Tax Declaration',
               route: '/transport-management/reports/tax-declaration-report',
            },
            {
               heading: 'Model',
               route: '/transport-management/reports/overall-model-report',
               permission: 'transport_reports',
            },
            {
               sectionTitle: 'Purchase',
               sub: [
                  {
                     heading: 'Purchase Models',
                     route: '/transport-management/reports/purchase-transaction-reports',
                     permission: 'transport_reports',
                  },
                  {
                     heading: 'Product Purchase',
                     route: '/transport-management/reports/product-purchase-report',
                     permission: 'transport_reports',
                  },
                  {
                     heading: 'Purchases Transaction with tax',
                     route: '/transport-management/reports/purchase-business-transaction-report',
                     permission: 'transport_reports',
                  },
                  {
                     heading: 'Salesman Purchase Transactions',
                     route: '/transport-management/reports/salesman-purchase-transactions-report',
                     permission: 'transport_reports',
                  },
                  {
                     heading: 'Transporter Account Statement',
                     route: '/transport-management/reports/vendor-account-statement-report',
                     permission: 'transport_reports',
                  },
                  {
                     heading: 'Transporter Groups Total Transactions',
                     route: '/transport-management/reports/vendors-groups-details-transactions-report',
                     permission: 'transport_reports',
                  },
                  {
                     heading: 'Transporters Transaction',
                     route: '/transport-management/reports/vendors-transaction-report',
                     permission: 'transport_reports',
                  },
               ],
            },
            {
               sectionTitle: 'Sales',
               sub: [
                  {
                     heading: 'Sales Transaction',
                     route: '/transport-management/reports/overall-detailed-reports',
                     permission: 'transport_reports',
                  },
                  {
                     heading: 'Product Sales',
                     route: '/transport-management/reports/product-sales-report',
                     permission: 'transport_reports',
                  },
                  {
                     heading: 'Sales with tax',
                     route: '/transport-management/reports/sales-business-transaction-report',
                     permission: 'transport_reports',
                  },
                  {
                     heading: 'Sales of representatives',
                     route: '/transport-management/reports/salesman-sales-transactions-report',
                     permission: 'transport_reports',
                  },
                  {
                     heading: 'Clients Account Statement',
                     route: '/transport-management/reports/client-account-statement-report',
                     permission: 'transport_reports',
                  },
                  {
                     heading: 'Clients Groups Details Transactions',
                     route: '/transport-management/reports/clients-groups-details-transactions-report',
                     permission: 'transport_reports',
                  },
                  {
                     heading: 'Client/Products Transaction',
                     route: '/transport-management/reports/clients-transaction-report',
                     permission: 'transport_reports',
                  },
               ],
            },
            {
               sectionTitle: 'Transportation',
               sub: [
                  {
                     heading: 'Transport Transactions',
                     route: '/transport-management/reports/generate-transport-transaction-reports',
                     permission: 'transport_reports',
                  },
                  {
                     heading: 'Detailed Transport Transactions',
                     route: '/transport-management/reports/generate-detailed-transport-transaction-reports',
                     permission: 'transport_reports',
                  },
                  {
                     heading: 'Return Transactions',
                     route: '/transport-management/reports/generate-return-transport-transaction-reports',
                     permission: 'transport_reports',
                  },
                  {
                     heading: 'Downloads transactions',
                     route: '/transport-management/reports/generate-download-transport-transaction-reports',
                     permission: 'transport_reports',
                  },
                  {
                     heading: 'Transport Transaction Expenses',
                     route: '/transport-management/reports/generate-total-transportation-travel-cost',
                     permission: 'transport_reports',
                  },
                  {
                     heading: 'Detailed Transport Transaction Expenses',
                     route: '/transport-management/reports/generate-detailed-transportation-travel-cost',
                     permission: 'transport_reports',
                  },
               ],
            },
         ],
      },
   ]
export default CargoMenuConfig;
