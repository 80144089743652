const CustomsMenuConfig = [
   {
      heading: 'settings',
      route: '/settings',
      module_id: 30,
      pages: [
         {
            heading: 'customs settings',
            route: '/customs-management/setting',
            permission: 'customs_setting',
         },
         {
            heading: 'Unit Of Measure',
            route: '/customs-management/unit',
            permission: 'units',
         },
         {
            heading: 'Taxes',
            route: '/customs-management/taxes',
            permission: 'taxes',
         },
         {
            heading: 'Clients Groups',
            route: '/customs-management/client-group',
            permission: 'client_groups',
         },
         {
            heading: 'Clients',
            route: '/customs-management/clients',
            permission: 'clients',
         },
         {
            heading: 'customsBrokers',
            route: '/customs-management/brokers',
            permission: 'customs_brokers',
         },
         {
            heading: 'Agents',
            route: '/customs-management/agent',
            permission: 'customs_agents',
         },
         {
            heading: 'Nationalities',
            route: '/customs-management/nationalities',
            permission: 'nationalities',
         },
         {
            heading: 'containers',
            route: '/customs-management/containers',
            permission: 'customs_containers',
         },
         {
            heading: 'Documents',
            route: '/customs-management/documents',
            permission: 'customs_documents',
         },
         {
            heading: 'services',
            route: '/customs-management/services',
            permission: 'customs_services',
         },
         {
            heading: 'customTransactions',
            route: '/customs-management/transactions',
            permission: 'customs_transactions',
         },
         {
            heading: 'expensesAndIncomes',
            route: '/customs-management/expenses-incomes',
            permission: 'customs_expense_revenues',
         },
         {
            heading: 'Workflow Forms',
            route: '/customs-management/customs-model-settings',
            permission: 'customs_invoice_models',
         },
         {
            heading: 'invoiceItems',
            route: '/customs-management/invoice-items',
            permission: 'customs_invoice_items',
         },
      ],
   },
   {
      heading: 'Daily Transactions',
      route: '/daily-transactions',
      module_id: 30,
      name: 'daily_movements',
      pages: [],
   },
   {
      heading: 'Reports',
      route: '/reports',
      pages: [
         {
            heading: 'Tax Declaration',
            route: '/customs-management/tax-declaration-report',
         },
         {
            sectionTitle: 'Sales',
            sub: [
               {
                  heading: 'Model',
                  route: '/customs-management/overall-model-report',
                  permission: 'customs_reports',
               },
               {
                  heading: 'Sales Transaction',
                  route: '/customs-management/overall-detailed-reports',
                  permission: 'customs_reports',
               },
               {
                  heading: 'Service Sales',
                  route: '/customs-management/product-sales-report',
                  permission: 'customs_reports',
               },
               {
                  heading: 'Sales with tax',
                  route: '/customs-management/sales-business-transaction-report',
                  permission: 'customs_reports',
               },
               {
                  heading: 'Customs Broker Sales Transactions',
                  route: '/customs-management/customs-broker-sales-transactions-report',
                  permission: 'customs_reports',
               },
               {
                  heading: 'Clients Account Statement',
                  route: '/customs-management/client-account-statement-report',
                  permission: 'customs_reports',
               },
               {
                  heading: 'Clients Groups Details Transactions',
                  route: '/customs-management/clients-groups-details-transactions-report',
                  permission: 'customs_reports',
               },
               {
                  heading: 'Client/Products Transaction',
                  route: '/customs-management/clients-transaction-report',
                  permission: 'customs_reports',
               },
            ],
         },
         {
            heading: 'Client Deals',
            route: '/customs-management/client-deals-report',
            permission: 'customs_reports',
         },
         {
            heading: 'Broker Deals',
            route: '/customs-management/broker-deals-report',
            permission: 'customs_reports',
         },
         {
            heading: 'licenses of customs brokers',
            route: '/customs-management/expiry-customs-brokers-report',
            permission: 'customs_reports',
         },
         {
            heading: 'Deals In A Duration',
            route: '/customs-management/deals-in-a-duration-report',
            permission: 'customs_reports',
         },
         {
            heading: 'Incomes and Expenses',
            route: '/customs-management/incomes-expenses-report',
            permission: 'customs_reports',
         },
         {
            heading: 'Reopened Deals',
            route: '/customs-management/reopened-deals',
            permission: 'customs_reports',
         },
      ],
   },
];

export default CustomsMenuConfig;
